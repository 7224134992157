import { useState, useEffect, useCallback, useRef } from "react";
import "../dashboard/Dashboard.css";  // Pulls in your .css
import deployWidgetRequest from "../api/deployWidgetRequest.js";
import deleteWidgetRequest from "../api/deleteWidgetRequest.js";
import { log } from "../utils/logger.js";
import { getFlowNames } from "../api/fetchFlows.js";
import { encrypt } from "../utils/crypt.js";

/** Default color / text settings */
const DEFAULTS = {
  primaryColor: '#0056b3',
  headerColor: '#0056b3',
  userBubbleColor: '#0056b3',
  assistantBubbleColor: '#ffffff',
  backgroundGradientFrom: '#ebf8ff',
  backgroundGradientTo: '#ffffff',

  headerTextColor: '#ffffff',
  userTextColor: '#ffffff',
  assistantTextColor: '#171717',

  headerTitle: 'Support Chat',
  initialMessage: 'Hello! How can I help you today?',
  stockReply: 'Thank you for your message. Our team will get back to you shortly.'
};

export default function WidgetDeployLogic({ isDarkMode, userEmail, styles, depsBrah, wait, userPlan }) {
  // Basic widget info
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [description, setDescription] = useState('');
  const [flow, setFlow] = useState('');
  const [copyButtonText, setCopyButtonText] = useState('Copy');

  // Widget color / text states
  const [primaryColor, setPrimaryColor] = useState(DEFAULTS.primaryColor);
  const [headerColor, setHeaderColor] = useState(DEFAULTS.headerColor);
  const [userBubbleColor, setUserBubbleColor] = useState(DEFAULTS.userBubbleColor);
  const [assistantBubbleColor, setAssistantBubbleColor] = useState(DEFAULTS.assistantBubbleColor);
  const [backgroundGradientFrom, setBackgroundGradientFrom] = useState(DEFAULTS.backgroundGradientFrom);
  const [backgroundGradientTo, setBackgroundGradientTo] = useState(DEFAULTS.backgroundGradientTo);

  const [headerTextColor, setHeaderTextColor] = useState(DEFAULTS.headerTextColor);
  const [userTextColor, setUserTextColor] = useState(DEFAULTS.userTextColor);
  const [assistantTextColor, setAssistantTextColor] = useState(DEFAULTS.assistantTextColor);

  // Other widget settings
  const [headerIcon, setHeaderIcon] = useState('');
  const [headerTitle, setHeaderTitle] = useState(DEFAULTS.headerTitle);
  const [initialMessage, setInitialMessage] = useState(DEFAULTS.initialMessage);
  const [stockReply, setStockReply] = useState(DEFAULTS.stockReply);

  // Deployment states
  const [widgetScript, setWidgetScript] = useState('');
  const [isWidgetDeployed, setIsWidgetDeployed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [widgetDomain, setWidgetDomain] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // All deployments & flows
  const [deployments, setDeployments] = useState(depsBrah);
  const [flows, setFlows] = useState([]);

  // Single toggle for the color panel
  const [showColorPanel, setShowColorPanel] = useState(false);

  // For copying script
  const scriptRef = useRef(null);

  // Fetch flows
  const fetchFlows = useCallback(async () => {
    try {
      log('Getting flows...');
      const flowNames = await getFlowNames(userEmail);
      setFlows(flowNames || []);
      log('Flows:', flowNames);
    } catch (error) {
      console.error('Error in fetchFlows:', error);
      setFlows([]);
    }
  }, [userEmail]);

  useEffect(() => {
    fetchFlows();
  }, [fetchFlows]);

  // Validate widget title
  const validateTitle = (value) => {
    const validTitleRegex = /^[a-zA-Z0-9-\s]+$/;
    if (!validTitleRegex.test(value)) {
      setTitleError('Title can only contain letters, numbers, and hyphens.');
      return false;
    }
    setTitleError('');
    return true;
  };

  // Handles editing an existing deployment
  const handleEdit = (deployment) => {
    // Re-hydrate all the fields
    setTitle(deployment.title || deployment.TITLE || '');
    setDescription(deployment.description || deployment.DESCRIPTION || '');
    
    // Colors
    setPrimaryColor(deployment.primaryColor || deployment.PRIMARY_COLOR || DEFAULTS.primaryColor);
    setHeaderColor(deployment.headerColor || deployment.HEADER_COLOR || DEFAULTS.headerColor);
    setUserBubbleColor(deployment.userBubbleColor || deployment.USER_BUBBLE_COLOR || DEFAULTS.userBubbleColor);
    setAssistantBubbleColor(deployment.assistantBubbleColor || deployment.ASSISTANT_BUBBLE_COLOR || DEFAULTS.assistantBubbleColor);
    setBackgroundGradientFrom(deployment.backgroundGradientFrom || deployment.BACKGROUND_GRADIENT_FROM || DEFAULTS.backgroundGradientFrom);
    setBackgroundGradientTo(deployment.backgroundGradientTo || deployment.BACKGROUND_GRADIENT_TO || DEFAULTS.backgroundGradientTo);

    // Text colors
    setHeaderTextColor(deployment.headerTextColor || deployment.HEADER_TEXT_COLOR || DEFAULTS.headerTextColor);
    setUserTextColor(deployment.userTextColor || deployment.USER_TEXT_COLOR || DEFAULTS.userTextColor);
    setAssistantTextColor(deployment.assistantTextColor || deployment.ASSISTANT_TEXT_COLOR || DEFAULTS.assistantTextColor);

    // Icon / text
    setHeaderIcon(deployment.headerIcon || deployment.HEADER_ICON || '');
    setHeaderTitle(deployment.headerTitle || deployment.HEADER_TITLE || DEFAULTS.headerTitle);
    setInitialMessage(deployment.initialMessage || deployment.INITIAL_MESSAGE || DEFAULTS.initialMessage);
    setStockReply(deployment.stockReply || deployment.STOCK_REPLY || DEFAULTS.stockReply);

    // Flow
    const flowName = deployment.FLOWNAME || deployment.FLOW || '';
    const formattedFlow = flowName.startsWith('Flow: ') ? flowName : `Flow: ${flowName}`;
    setFlow(formattedFlow);

    setIsEditing(true);
    setIsWidgetDeployed(false);
    setWidgetDomain(deployment.domain || deployment.DOMAIN || '');
  };

  // Handles deleting an existing deployment
  const handleDelete = async (title) => {
    if (window.confirm(`Are you sure you want to delete the widget "${title}"?`)) {
      try {
        setIsLoading(true);
        await deleteWidgetRequest(userEmail, title);
        setIsLoading(false);
        alert(`Widget "${title}" deleted successfully.`);

        setDeployments(prev => {
          const newDeployments = { ...prev };
          delete newDeployments[title];
          return newDeployments;
        });
      } catch (error) {
        console.error("Error deleting widget:", error);
        setIsLoading(false);
        alert(`Failed to delete widget "${title}". Please try again.`);
      }
    }
  };

  // Title change
  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    validateTitle(newTitle);
  };

  // Generate the embed script
  const generateWidgetScript = (domain) => {
    const baseUrl = domain || 'chat.vectorvault.io';
    const deploymentId = title ? `widget:${title}` : 'widget:';

    let script = `<!-- VectorVault Chat Widget -->
<script src="https://${baseUrl}/chat-widget.js"
    data-user="${encrypt(userEmail)}"
    data-deployment-id="${deploymentId}"
    data-widget="true"`;

    // Add non-default attributes
    if (primaryColor !== DEFAULTS.primaryColor) {
      script += `\n    data-primary-color="${primaryColor}"`;
    }
    if (headerColor !== DEFAULTS.headerColor) {
      script += `\n    data-header-color="${headerColor}"`;
    }
    if (userBubbleColor !== DEFAULTS.userBubbleColor) {
      script += `\n    data-user-bubble-color="${userBubbleColor}"`;
    }
    if (assistantBubbleColor !== DEFAULTS.assistantBubbleColor) {
      script += `\n    data-assistant-bubble-color="${assistantBubbleColor}"`;
    }
    if (backgroundGradientFrom !== DEFAULTS.backgroundGradientFrom) {
      script += `\n    data-background-gradient-from="${backgroundGradientFrom}"`;
    }
    if (backgroundGradientTo !== DEFAULTS.backgroundGradientTo) {
      script += `\n    data-background-gradient-to="${backgroundGradientTo}"`;
    }
    if (headerIcon) {
      script += `\n    data-header-icon="${headerIcon}"`;
    }
    if (headerTitle !== DEFAULTS.headerTitle) {
      script += `\n    data-header-title="${headerTitle}"`;
    }
    if (initialMessage !== DEFAULTS.initialMessage) {
      script += `\n    data-initial-message="${initialMessage}"`;
    }
    if (stockReply !== DEFAULTS.stockReply) {
      script += `\n    data-stock-reply="${stockReply}"`;
    }

    // Text colors
    if (headerTextColor !== DEFAULTS.headerTextColor) {
      script += `\n    data-header-text-color="${headerTextColor}"`;
    }
    if (userTextColor !== DEFAULTS.userTextColor) {
      script += `\n    data-user-text-color="${userTextColor}"`;
    }
    if (assistantTextColor !== DEFAULTS.assistantTextColor) {
      script += `\n    data-assistant-text-color="${assistantTextColor}"`;
    }

    script += '\n></script>';

    setWidgetScript(script);
    return script;
  };

  // Copy script to clipboard
  const copyScriptToClipboard = async () => {
    if (!widgetScript) {
      console.error("No script content to copy");
      return;
    }
  
    try {
      // Try using the modern Clipboard API
      await navigator.clipboard.writeText(widgetScript);
      // Show "Copied" in the button for 3 seconds
      setCopyButtonText('Copied!');
      setTimeout(() => {
        setCopyButtonText('Copy');
      }, 2000);
    } catch (err) {
      console.warn("Clipboard API failed, trying fallback method", err);
      
      // Fallback method
      try {
        // Create a temporary textarea element
        const textArea = document.createElement('textarea');
        textArea.value = widgetScript;
        
        // Make it invisible but part of the document
        textArea.style.position = 'fixed';
        textArea.style.opacity = '0';
        document.body.appendChild(textArea);
        
        // Select and copy
        textArea.select();
        const successful = document.execCommand('copy');
        
        // Clean up
        document.body.removeChild(textArea);
        
        if (successful) {
          // Show "Copied" in the button for 3 seconds
          setCopyButtonText('Copied!');
          setTimeout(() => {
            setCopyButtonText('Copy');
          }, 3000);
        } else {
          throw new Error('Copy command unsuccessful');
        }
      } catch (fallbackErr) {
        console.error('Copy fallback failed:', fallbackErr);
        setCopyButtonText('Error!');
        setTimeout(() => {
          setCopyButtonText('Copy');
        }, 3000);
      }
    }
  };  

  // Reset to default widget values
  const resetToDefaults = () => {
    setTitle('');
    setTitleError('');
    setDescription('');
    setFlow('');

    setPrimaryColor(DEFAULTS.primaryColor);
    setHeaderColor(DEFAULTS.headerColor);
    setUserBubbleColor(DEFAULTS.userBubbleColor);
    setAssistantBubbleColor(DEFAULTS.assistantBubbleColor);
    setBackgroundGradientFrom(DEFAULTS.backgroundGradientFrom);
    setBackgroundGradientTo(DEFAULTS.backgroundGradientTo);

    setHeaderTextColor(DEFAULTS.headerTextColor);
    setUserTextColor(DEFAULTS.userTextColor);
    setAssistantTextColor(DEFAULTS.assistantTextColor);

    setHeaderIcon('');
    setHeaderTitle(DEFAULTS.headerTitle);
    setInitialMessage(DEFAULTS.initialMessage);
    setStockReply(DEFAULTS.stockReply);

    setIsEditing(false);
    setIsWidgetDeployed(false);
    setWidgetDomain('');
    setWidgetScript('');
  };

  // Toggle the single color panel
  const toggleColorPanel = () => {
    setShowColorPanel(prev => !prev);
  };

  // Refresh deployments list after creation or update
  const refreshDeployments = (newDeployment) => {
    if (newDeployment) {
      setDeployments(prev => ({
        ...prev,
        [newDeployment.title]: newDeployment
      }));
    }
  };

  const handleShowScript = (deployment) => {
    const domain = 'chat.vectorvault.io';
    setPrimaryColor(deployment.primaryColor || deployment.PRIMARY_COLOR || DEFAULTS.primaryColor);
    setHeaderColor(deployment.headerColor || deployment.HEADER_COLOR || DEFAULTS.headerColor);
    setUserBubbleColor(deployment.userBubbleColor || deployment.USER_BUBBLE_COLOR || DEFAULTS.userBubbleColor);
    setAssistantBubbleColor(deployment.assistantBubbleColor || deployment.ASSISTANT_BUBBLE_COLOR || DEFAULTS.assistantBubbleColor);
    setBackgroundGradientFrom(deployment.backgroundGradientFrom || deployment.BACKGROUND_GRADIENT_FROM || DEFAULTS.backgroundGradientFrom);
    setBackgroundGradientTo(deployment.backgroundGradientTo || deployment.BACKGROUND_GRADIENT_TO || DEFAULTS.backgroundGradientTo);
    setHeaderTextColor(deployment.headerTextColor || deployment.HEADER_TEXT_COLOR || DEFAULTS.headerTextColor);
    setUserTextColor(deployment.userTextColor || deployment.USER_TEXT_COLOR || DEFAULTS.userTextColor);
    setAssistantTextColor(deployment.assistantTextColor || deployment.ASSISTANT_TEXT_COLOR || DEFAULTS.assistantTextColor);
    setHeaderIcon(deployment.headerIcon || deployment.HEADER_ICON || '');
    setHeaderTitle(deployment.headerTitle || deployment.HEADER_TITLE || DEFAULTS.headerTitle);
    setInitialMessage(deployment.initialMessage || deployment.INITIAL_MESSAGE || DEFAULTS.initialMessage);
    setStockReply(deployment.stockReply || deployment.STOCK_REPLY || DEFAULTS.stockReply);

    // Re-hydrate all the fields
    setTitle(deployment.title || deployment.TITLE || '');
    setDescription(deployment.description || deployment.DESCRIPTION || '');
    
    // Flow
    const flowName = deployment.FLOWNAME || deployment.FLOW || '';
    const formattedFlow = flowName.startsWith('Flow: ') ? flowName : `Flow: ${flowName}`;
    setFlow(formattedFlow);

    // Generate script with proper deployment ID
    const deploymentId = `widget:${deployment.title || deployment.TITLE}`;
    
    let script = `<!-- VectorVault Chat Widget -->
<script src="https://${domain}/chat-widget.js"
  data-user="${encrypt(userEmail)}"
  data-deployment-id="${deploymentId}"
  data-widget="true"`;

    // Add non-default attributes
    const settings = {
      'primary-color': deployment.primaryColor || deployment.PRIMARY_COLOR,
      'header-color': deployment.headerColor || deployment.HEADER_COLOR,
      'user-bubble-color': deployment.userBubbleColor || deployment.USER_BUBBLE_COLOR,
      'assistant-bubble-color': deployment.assistantBubbleColor || deployment.ASSISTANT_BUBBLE_COLOR,
      'background-gradient-from': deployment.backgroundGradientFrom || deployment.BACKGROUND_GRADIENT_FROM,
      'background-gradient-to': deployment.backgroundGradientTo || deployment.BACKGROUND_GRADIENT_TO,
      'header-text-color': deployment.headerTextColor || deployment.HEADER_TEXT_COLOR,
      'user-text-color': deployment.userTextColor || deployment.USER_TEXT_COLOR,
      'assistant-text-color': deployment.assistantTextColor || deployment.ASSISTANT_TEXT_COLOR,
      'header-icon': deployment.headerIcon || deployment.HEADER_ICON,
      'header-title': deployment.headerTitle || deployment.HEADER_TITLE,
      'initial-message': deployment.initialMessage || deployment.INITIAL_MESSAGE,
      'stock-reply': deployment.stockReply || deployment.STOCK_REPLY,
    };
  
    // Add each non-default setting to the script
    Object.entries(settings).forEach(([key, value]) => {
      if (value && value !== DEFAULTS[key.replace(/-([a-z])/g, (g) => g[1].toUpperCase())]) {
        script += `\n  data-${key}="${value}"`;
      }
    });
  
    script += '\n></script>';
    
    // Update states
    setWidgetDomain(domain);
    setIsWidgetDeployed(true);
    setIsEditing(false);
    setWidgetScript(script);
    
    // Scroll to the script area
    setTimeout(() => {
      const scriptArea = document.querySelector('textarea');
      if (scriptArea) {
        scriptArea.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };


  // Submit (deploy or update) widget
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateTitle(title)) return;
    if (userPlan === 'free') {
      alert('You must be a paying user to deploy a widget. Click "SignUp" in the sidebar to continue.');
      return;
    }
    if (!title || !flow) {
      alert("Title and Flow are required fields for a widget deployment.");
      return;
    }

    setIsLoading(true);

    try {
      const processedFlow = flow.startsWith('Flow:') ? flow.substring(5).trim() : flow;
      const widgetConfig = {
        userEmail,
        title,
        description,
        processedFlow,
        primaryColor,
        headerColor,
        userBubbleColor,
        assistantBubbleColor,
        backgroundGradientFrom,
        backgroundGradientTo,

        headerTextColor,
        userTextColor,
        assistantTextColor,

        headerIcon,
        headerTitle,
        initialMessage,
        stockReply
      };

      const response = await deployWidgetRequest(widgetConfig);

      setWidgetDomain(response.domain);
      setIsWidgetDeployed(true);
      setIsEditing(false);

      // Generate the script
      generateWidgetScript(response.domain);

      // Update the list
      const newDeployment = {
        TITLE: title,
        DOMAIN: response.domain,
        FLOWNAME: processedFlow,
        DESCRIPTION: description,

        primaryColor,
        headerColor,
        userBubbleColor,
        assistantBubbleColor,
        backgroundGradientFrom,
        backgroundGradientTo,

        headerTextColor,
        userTextColor,
        assistantTextColor,

        headerIcon,
        headerTitle,
        initialMessage,
        stockReply
      };
      refreshDeployments(newDeployment);

      alert(`Chat Widget deployed successfully at: ${response.domain}\nYou can now copy the embed script to add to your website.`);
    } catch (error) {
      console.error("Error in deployment request:", error);
      alert(`Error during deployment: ${error.message || 'Unknown error'}`);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    // Basic props & data
    isDarkMode,
    styles,
    isLoading,
    isEditing,
    isWidgetDeployed,
    widgetScript,
    widgetDomain,
    deployments: depsBrah,
    flows,

    // Widget states
    title, setTitle,
    titleError,
    description, setDescription,
    flow, setFlow,

    // Colors
    primaryColor, setPrimaryColor,
    headerColor, setHeaderColor,
    userBubbleColor, setUserBubbleColor,
    assistantBubbleColor, setAssistantBubbleColor,
    backgroundGradientFrom, setBackgroundGradientFrom,
    backgroundGradientTo, setBackgroundGradientTo,
    headerTextColor, setHeaderTextColor,
    userTextColor, setUserTextColor,
    assistantTextColor, setAssistantTextColor,

    // Other widget fields
    headerIcon, setHeaderIcon,
    headerTitle, setHeaderTitle,
    initialMessage, setInitialMessage,
    stockReply, setStockReply,

    // Toggling color panel
    showColorPanel,
    toggleColorPanel,

    // CRUD handlers
    handleEdit,
    handleDelete,
    handleTitleChange,
    copyScriptToClipboard,
    resetToDefaults,
    handleSubmit,
    handleShowScript,
    copyButtonText
  };
}
