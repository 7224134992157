import { postMail } from "./credationStation";

export const fetchDeployments = async (userEmail) => {
    try {
        const result = await postMail(userEmail.toLowerCase());
        return result.deployments;
    } catch (error) {
        console.error("Error fetching deployments:", error);
        throw error;
    }
};