import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { hashPassword } from '../utils/hashPassword';

export default function ResetFlowPasswordPage() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  // Grab the 'token' from the URL, e.g. ?token=abcdef
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const navigate = useNavigate();

  // Optionally, check if token is missing or invalid right away
  useEffect(() => {
    if (!token) {
      setError('No reset token provided. Please check your email link.');
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Basic validations
    if (!token) {
      setError('Missing or invalid token.');
      return;
    }
    if (!newPassword || !confirmPassword) {
      setError('Please fill out both password fields.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setLoading(true);

    try {
      // Hash the new password on the front end
      const hashedPass = await hashPassword(newPassword);
      // Post to your backend
      const response = await fetch('https://api.vectorvault.io/reset_flow_password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token,
          newHashedPassword: hashedPass,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // If successful, show success or redirect user, etc.
        setSuccess('Password reset successful! You can now lock or unlock your flow with the new password.');
      } else {
        setError(data.message || 'Failed to reset password.');
      }
    } catch (err) {
      console.error('Error resetting flow password:', err);
      setError('An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '500px', margin: '2rem auto' }}>
      <h2>Reset Flow Password</h2>
      {error && <div style={{ color: 'red', marginBottom: '1rem' }}>{error}</div>}
      {success && <div style={{ color: 'green', marginBottom: '1rem' }}>{success}</div>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>New Password</label>
          <input
            type="password"
            className="form-control"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            disabled={loading}
          />
        </div>

        <div className="form-group" style={{ marginTop: '1rem' }}>
          <label>Confirm New Password</label>
          <input
            type="password"
            className="form-control"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={loading}
          />
        </div>

        <button 
          type="submit" 
          style={{ marginTop: '1.5rem', padding: '0.5rem 1rem' }}
          disabled={loading}
        >
          {loading ? 'Resetting...' : 'Reset Password'}
        </button>
      </form>

      {success && (
        <button
          style={{ marginTop: '1.5rem', padding: '0.5rem 1rem' }}
          onClick={() => navigate('/login')}
        >
          Return to Login
        </button>
      )}
    </div>
  );
}
