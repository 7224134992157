import { encrypt } from "../utils/crypt.js";
import { log } from "../utils/logger.js";

/**
 * Sends a request to delete a chat widget deployment
 * 
 * @param {string} userEmail - User email
 * @param {string} widgetTitle - Widget title (used to generate deployment ID)
 * @returns {Promise<Object>} Deletion response
 */
async function deleteWidgetRequest(userEmail, widgetTitle) {
    try {
        // Create deployment ID based on title (must match deployment format)
        const deploymentId = `widget:${widgetTitle}`;
        
        const API_URL = 'https://api.vectorvault.io/';
        const userEncrypted = encrypt(userEmail);
        
        // Construct request payload
        const payload = {
            email: userEncrypted,
            deployment_id: deploymentId
        };
        
        // Log request payload for debugging
        log('Widget deletion request payload:', payload);
        
        // Send DELETE request
        const response = await fetch(`${API_URL}/widget-deployment`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });
        
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to delete widget: ${errorText}`);
        }
        
        // Parse and return response
        const data = await response.json();
        log('Widget deletion response:', data);
        
        return data;
    } catch (error) {
        console.error('Error in widget deletion request:', error);
        throw error;
    }
}

export default deleteWidgetRequest;
