// credationStation.js
import { encrypt } from "../utils/crypt";
import { log } from "../utils/logger";

const postMail = async (userEmail) => {
    const encryptEmail = encrypt(userEmail);
    try {
        const formData = new URLSearchParams();
        // log(`User encrypted email: ${encryptEmail}`)
        formData.append('email', encryptEmail);

        const response = await fetch("https://register.vectorvault.io/get_client_data", {
            method: 'POST',
            headers: {
                "Authorization": 'unexpected_authorization_code'
            },
            body: formData
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error(`HTTP Error: ${response.status}`, errorBody);
            return;
        }

        const result = await response.json();
        localStorage.setItem('userPlan', result.plan);
        localStorage.setItem('customerID', result.id);
        localStorage.setItem('firstName', result.fname);
        localStorage.setItem('lastName', result.lname);
        localStorage.setItem('requests', result.requests);
        localStorage.setItem('uuid', result.uuid);
        return result;
    } catch (error) {
        console.error("There was an error:", error);
        throw error;
    }
};

export { postMail };
