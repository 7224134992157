import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import encrypt from '../api/encrypt';

function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      setError('Missing reset token in URL.');
    }
  }, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    // Basic validation
    if (!token) {
      setError('No token found. Check your link or request a new reset email.');
      return;
    }
    if (!newPassword || !confirmPassword) {
      setError('Please fill out both fields.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setLoading(true);

    try {
      // Encrypt the new password
      const encryptedPassword = encrypt(newPassword);

      // Send to backend
      const res = await fetch('https://register.vectorvault.io/reset_account_password', {
        method: 'POST',
        headers: {
          'Authorization': 'expected_authorization_code',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          new_encrypted_password: encryptedPassword
        }),
      });

      const data = await res.json();

      if (!res.ok) {
        // Show error if the request failed
        setError(data.message || 'Failed to reset password.');
      } else {
        setSuccess('Your password has been reset successfully!');
      }
    } catch (err) {
      console.error(err);
      setError('An unexpected error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: 400, margin: '2rem auto' }}>
      <h2>Reset Password</h2>

      {error && <div style={{ color: 'red', marginBottom: '1rem' }}>{error}</div>}
      {success && <div style={{ color: 'green', marginBottom: '1rem' }}>{success}</div>}

      {!success && (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>New Password:</label>
            <input
              type="password"
              className="form-control"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              disabled={loading}
            />
          </div>
          <div className="form-group" style={{ marginTop: '1rem' }}>
            <label>Confirm Password:</label>
            <input
              type="password"
              className="form-control"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={loading}
            />
          </div>
          
          <button type="submit" style={{ marginTop: '1rem' }} disabled={loading}>
            {loading ? 'Submitting...' : 'Reset Password'}
          </button>
        </form>
      )}

      {success && (
        <button 
          style={{ marginTop: '1rem' }} 
          onClick={() => navigate('/login')}
        >
          Back to Login
        </button>
      )}
    </div>
  );
}

export default ResetPasswordPage;
