import { encrypt } from "../utils/crypt.js";
import { log } from "../utils/logger.js";

/**
 * Sends a request to deploy a chat widget
 * 
 * @param {Object} widgetConfig - Configuration for the widget deployment
 * @param {string} widgetConfig.userEmail - User email
 * @param {string} widgetConfig.title - Widget title
 * @param {string} widgetConfig.description - Widget description
 * @param {string} widgetConfig.processedFlow - Flow name for the deployment
 * @param {string} widgetConfig.primaryColor - Primary theme color
 * @param {string} widgetConfig.headerColor - Header background color
 * @param {string} widgetConfig.userBubbleColor - User message bubble color
 * @param {string} widgetConfig.assistantBubbleColor - Assistant message bubble color
 * @param {string} widgetConfig.backgroundGradientFrom - Background gradient start color
 * @param {string} widgetConfig.backgroundGradientTo - Background gradient end color
 * @param {string} widgetConfig.headerIcon - URL for header icon
 * @param {string} widgetConfig.headerTitle - Title displayed in widget header
 * @param {string} widgetConfig.initialMessage - Initial greeting message
 * @param {string} widgetConfig.stockReply - Fallback reply message
 * @returns {Promise<Object>} Deployment response with domain information
 */
async function deployWidgetRequest(widgetConfig) {
    try {
        // Extract required parameters
        const { 
            userEmail, 
            title, 
            description, 
            processedFlow,  // This should be the flow name already processed
            primaryColor,
            headerColor,
            userBubbleColor,
            assistantBubbleColor,
            backgroundGradientFrom,
            backgroundGradientTo,
            headerIcon,
            headerTitle,
            initialMessage,
            stockReply
        } = widgetConfig;
        
        // Create deployment ID in the required format (just add widget: prefix)
        const deploymentId = `widget:${title}`;
        
        const API_URL = 'https://api.vectorvault.io/';
        const userEncrypted = encrypt(userEmail);
        
        // Construct the request payload with all widget configuration data
        const payload = {
            email: userEncrypted,
            deployment_id: deploymentId,
            flow_name: processedFlow,  // Using the processed flow name directly
            
            // Widget metadata
            title: title,
            description: description,
            
            // Appearance settings
            primary_color: primaryColor,
            header_color: headerColor,
            user_bubble_color: userBubbleColor,
            assistant_bubble_color: assistantBubbleColor,
            background_gradient_from: backgroundGradientFrom,
            background_gradient_to: backgroundGradientTo,
            header_icon: headerIcon,
            header_title: headerTitle,
            
            // Message settings
            initial_message: initialMessage,
            stock_reply: stockReply
        };
        
        // Log the request payload for debugging
        log('Widget deployment request payload:', payload);
        
        // Send the POST request to the API
        const response = await fetch(`${API_URL}/widget-deployment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });
        
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to deploy widget: ${errorText}`);
        }
        
        // Parse and return the response
        const data = await response.json();
        log('Widget deployment response:', data);
        
        return data;
    } catch (error) {
        console.error('Error in widget deployment request:', error);
        throw error;
    }
}

export default deployWidgetRequest;