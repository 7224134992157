// Deploy.js
import React, { useState, useEffect, useCallback, useContext } from "react";
import "../dashboard/Dashboard.css" 
import { fetchModelList } from "../api/modelAPI.js";
import sendDeployRequest from "../api/makeDeployment";
import deleteService from "../api/deleteDeployment";
import Deployments from "./DeploymentsTables";
import UserManagement from "./DeployUserManagement.js" 
import { setCookie, getCookie } from '../cookieUtils.js';
import { AuthContext }  from '../App.js'
import { log } from "../utils/logger.js";
import { fetchDeployments } from "../api/fetchDeployments.js";

function Deploy({ isDarkMode, userEmail, styles, depsBrah, wait, userPlan }) {
    const { vectorVault } = useContext(AuthContext);
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState('');
    const [description, setDescription] = useState('');
    const [vault, setVault] = useState('');
    const [forwardSlash, setForwardSlash] = useState('');
    const [chat, setChat] = useState(false);
    const [data, setData] = useState(false);
    const [edit, setEdit] = useState(false);
    const [del, setDel] = useState(false);
    const [model, setModel] = useState(false);
    const [dark, setDark] = useState(false);
    const [context, setContext] = useState(true);
    const [JSN, setJSN] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [models, setModels] = useState([]);
    const [showCustomModelInput, setShowCustomModelInput] = useState(false);
    const [customModelName, setCustomModelName] = useState('');
    const [deployments, setDeployments] = useState(depsBrah);
    
    // User management state
    const [isPublic, setIsPublic] = useState(true);
    const [users, setUsers] = useState([{ username: '', password: '' }]);
    
    log(depsBrah);
    
    // Add this useEffect to load deployments on component mount
    useEffect(() => {
        const loadDeployments = async () => {
            try {
                setIsLoading(true);
                const fetchedDeployments = await fetchDeployments(userEmail);
                
                // Filter out widget deployments
                const filteredDeployments = {};
                for (const [key, value] of Object.entries(fetchedDeployments || {})) {
                    if (!key.startsWith('widget:') && value.type !== 'widget') {
                        filteredDeployments[key] = value;
                    }
                }
                
                setDeployments(filteredDeployments);
            } catch (error) {
                console.error("Error loading deployments:", error);
                
                // If API fetch fails, use props but filter out widgets
                if (depsBrah) {
                    const filteredProps = {};
                    for (const [key, value] of Object.entries(depsBrah)) {
                        if (!key.startsWith('widget:') && value.type !== 'widget') {
                            filteredProps[key] = value;
                        }
                    }
                    setDeployments(filteredProps);
                }
            } finally {
                setIsLoading(false);
            }
        };
        
        loadDeployments();
        // Only run this on initial mount and when userEmail changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEmail]);
    
    const refreshDeployments = useCallback(async (newDeployment = null) => {
        try {
            setIsLoading(true);
            // Fetch all deployments from the server
            const fetchedDeployments = await fetchDeployments(userEmail);
            
            // Filter out widget deployments
            const filteredDeployments = {};
            for (const [key, value] of Object.entries(fetchedDeployments || {})) {
                if (!key.startsWith('widget:') && value.type !== 'widget') {
                    filteredDeployments[key] = value;
                }
            }
            
            setDeployments(filteredDeployments);
        
            // If there's a new deployment, we've already fetched everything,
            // so no need to manually add it to the state
        } catch (error) {
            console.error("Error refreshing deployments:", error);
            
            // If fetch fails but we have a new deployment to add, add it to the current state
            if (newDeployment) {
                const title = newDeployment.title || newDeployment.TITLE;
                if (!title.startsWith("widget:") && newDeployment.type !== "widget") {
                    setDeployments(prev => ({
                        ...prev,
                        [title]: newDeployment
                    }));
                }
            }
        } finally {
            setIsLoading(false);
        }
    }, [userEmail]);
    
    const [vaults, setVaults] = useState(() => {
        const storedVaults = getCookie('vaults');
        try {
          return storedVaults ? JSON.parse(storedVaults) : ['-'];
        } catch (e) {
          console.error("Error parsing vaults from cookie:", e);
          return ['-'];
        }
    });
    
    // Load OpenAI models
    useEffect(() => {
        async function loadModels() {
            const fetchedModels = await fetchModelList(userEmail);
            setModels(fetchedModels);
        }
        loadModels();
    }, [userEmail]);
    
    const handleModelChange = (e) => {
        const selectedModel = e.target.value;
        setModel(selectedModel);
        if (selectedModel === 'finetuned') {
            setShowCustomModelInput(true);
        } else {
            setShowCustomModelInput(false);
            setCustomModelName('');
        }
    };
    
    const getSelectedModel = () => {
        if (model === 'finetuned') {
            return customModelName.trim();
        } else {
            return model;
        }
    };
    
    const fetchVaults = useCallback(async () => {
        try {
            const fetchedVaults = await vectorVault.getVaults('');
            
            const updatedVaults = fetchedVaults.length === 0 || fetchedVaults[0] !== "-" 
                ? ["-", ...fetchedVaults] 
                : fetchedVaults;
    
            setVaults(updatedVaults);
    
            const stringifiedVaults = JSON.stringify(updatedVaults);
            setCookie("vaults", stringifiedVaults);
        } catch (error) {
            console.error('Error in fetchVaults:', error);
        }
    }, [vectorVault]);
    
    useEffect(() => {
        try {
            const storedVaults = getCookie("vaults");
            if (storedVaults) {
                const parsedVaults = JSON.parse(storedVaults);
                setVaults(parsedVaults);
            } else {
                fetchVaults();
            }
        } catch (error) {
            console.error("Error parsing stored vaults:");
        }
    }, [fetchVaults]);
    
    const validateTitle = (value) => {
        // Regular expression to check for valid characters
        const validTitleRegex = /^[a-zA-Z0-9-\s]+$/;
        
        if (!validTitleRegex.test(value)) {
            setTitleError('Title can only contain letters, numbers, and hyphens.');
            return false;
        }
        setTitleError('');
        return true;
    };
    
    const handleEdit = (deployment) => {
        log(deployment)
        setTitle(deployment.title || deployment.TITLE || '');
        setDescription(deployment.description || deployment.DESCRIPTION || '');
        setVault(deployment.vault || deployment.VAULT || '');
        setForwardSlash(deployment.forwardSlash || deployment.FORWARDSLASH || '');
        setChat(deployment.chat || deployment.CHAT || false);
        setData(deployment.data || deployment.DATA || false);
        setEdit(deployment.edit || deployment.EDIT || false);
        setDel(deployment.del || deployment.DELETE || false);
        setModel(deployment.model || deployment.MODEL || false);
        setDark(deployment.dark || deployment.DARK || false);
        setContext(deployment.context || deployment.CONTEXT || true);
        setJSN(deployment.JSN || deployment.JSON || false);
        
        // Fix for the isPublic field
        // Check for both lowercase and uppercase property names
        // Default to true if not specified
        const publicFlag = 
            deployment.isPublic !== undefined ? deployment.isPublic : 
            deployment.IS_PUBLIC !== undefined ? deployment.IS_PUBLIC : 
            true;
        setIsPublic(publicFlag);
        
        // Fix for the users field
        // Check for both users and AUTH_USERS arrays
        // If users exist, use them; otherwise create a default user entry
        if (
            (deployment.users && Array.isArray(deployment.users) && deployment.users.length > 0) ||
            (deployment.AUTH_USERS && Array.isArray(deployment.AUTH_USERS) && deployment.AUTH_USERS.length > 0)
        ) {
            // Use either users or AUTH_USERS, whichever exists
            const usersList = deployment.users || deployment.AUTH_USERS;
            setUsers(usersList);
        } else {
            setUsers([{ username: '', password: '' }]);
        }
    };
    
    const handleDelete = async (title) => {
        if (window.confirm(`Are you sure you want to delete the deployment "${title}"?`)) {
            try {
                setIsLoading(true);
                const response = await deleteService(userEmail, title);
                log(response);
                setIsLoading(false);
                await refreshDeployments();
                alert(`Deployment "${title}" deleted successfully.`);
                
                setDeployments(prev => {
                    const newDeployments = { ...prev };
                    delete newDeployments[title];
                    return newDeployments;
                });
            } catch (error) {
                console.error("Error deleting deployment:", error);
                setIsLoading(false);
                alert(`Failed to delete deployment "${title}". Please try again.`);
            }
        }
    };
    
    const handleTitleChange = (e) => {
        const newTitle = e.target.value.trimEnd();
        setTitle(newTitle);
        validateTitle(newTitle);
    };
    
    // User management handlers
    const handleAddUser = () => {
        setUsers([...users, { username: '', password: '' }]);
    };
    
    const handleRemoveUser = (index) => {
        const newUsers = [...users];
        newUsers.splice(index, 1);
        setUsers(newUsers);
    };
    
    const handleUserChange = (index, field, value) => {
        const newUsers = [...users];
        newUsers[index][field] = value;
        setUsers(newUsers);
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateTitle(title)) {
            return; // Stop submission if title is invalid
        }
        
        // Validate users data if not public
        if (!isPublic) {
            const validUsers = users.filter(user => user.username.trim() && user.password.trim());
            if (validUsers.length === 0) {
                alert("You must add at least one user with username and password when access is not public.");
                return;
            }
            // Only keep valid users (with both username and password)
            setUsers(validUsers);
        }
        
        if (userPlan !== 'free') {
            if (chat || data) {
                if (title && vault && forwardSlash) {
                    try {
                        setIsLoading(true)
                        
                        // Prepare users data
                        const usersData = isPublic ? [] : users.filter(user => user.username && user.password);
                        
                        const response = await sendDeployRequest(
                            userEmail, title, vault, forwardSlash, 
                            chat, data, edit, del, 
                            getSelectedModel(),
                            dark, context, description, JSN,
                            isPublic, usersData // Add new parameters for access control
                        );
                        log(response);
                        
                        // Create new deployment object
                        const newDeployment = {
                            TITLE: title,
                            DOMAIN: response.domain,
                            FORWARDSLASH: forwardSlash,
                            VAULT: vault,
                            DESCRIPTION: description,
                            CHAT: chat,
                            DATA: data,
                            EDIT: edit,
                            DELETE: del,
                            MODEL: getSelectedModel(),
                            DARK: dark,
                            CONTEXT: context,
                            JSON: JSN,
                            isPublic: isPublic,
                            users: isPublic ? [] : usersData
                        };
                        
                        // Refresh the deployments list with the new deployment
                        refreshDeployments(newDeployment);
                        setIsLoading(false)
                        alert(`Deployed successfully at url: ${response.domain} You can manage your active deployments below. \n*First deployment takes 15-30 mintues for the domain to go live.`)
                        
                        // Reset form
                        setTitle('');
                        setDescription('');
                        setVault('');
                        setForwardSlash('');
                        setChat(false);
                        setData(false);
                        setEdit(false);
                        setDel(false);
                        setModel('gpt-4o');
                        setDark(false);
                        setContext(true);
                        setJSN(false);
                        setIsPublic(true);
                        setUsers([{ username: '', password: '' }]);
                        setIsLoading(false);
                    } catch (error) {
                        console.error("Error in POST request:", error);
                        setIsLoading(false);
                        alert("Error creating deployment. Please try again.");
                    }
                } else { 
                    setIsLoading(false)
                    alert("'Title', 'Vault', 'Forward Slash', and 'API' are all required varibles that cannot be left blank")
                };
            } else { // no chat or data selected
                setIsLoading(false)
                alert("You must select an interface. Choose from 'Chat', 'Data', or both.")
            };
        } else { // on the free plan
            setIsLoading(false)
            alert('You must be a paying user to make a deployment. Click "SignUp" in the sidebar to conitnue.')
        };
    };
    
    return (
        <div style={{...styles.wrapper }}>
            <div className="App" style={{...styles.body, paddingTop: "50px"}}>
                <h1 style={{ marginBottom: '10px' }}>Deployments</h1>
                <p style={{ marginBottom: '30px' }}>
                Deployments are $10/mo and they make a live webstie for your vault. It gives you a link so you can share. 
                These Deployments are quick and easy ways to share the AI Agents and Chatbots you build. 
                Choose how visitors interact with your AI, and whether they should just chat with that AI, or be able to see the data too. Full control over read, write and delete. 
                If you have any questions, reach out to our support.
                </p>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    {/* Title input */}
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Title:
                        <input
                            type="text"
                            value={title}
                            onChange={handleTitleChange}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                        />
                        {titleError && <span style={{ color: 'red', fontSize: '0.8em' }}>{titleError}</span>}
                    </label>
                    
                    {/* Description input */}
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Description:
                        <input
                            type="text"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                        />
                    </label>
                    
                    {/* Vault input */}
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Vault:
                        <select
                            value={vault}
                            onChange={e => setVault(e.target.value)}
                            className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                            style={{ marginTop: '5px' }}
                        >
                            <option value="">Select a vault</option>
                            {vaults.map((v, index) => (
                                <option key={index} value={v}>{v}</option>
                            ))}
                        </select>
                    </label>
                    
                    {/* Forward Slash input */}
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Forward Slash:
                        <input
                            type="text"
                            value={forwardSlash}
                            onChange={e => setForwardSlash(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                        />
                    </label>
                    
                    {/* Model Selection */}
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Model:
                        <div style={{ display: 'flex', gap: '10px', marginTop: '5px' }}>
                            <select
                                className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                                value={model}
                                onChange={handleModelChange}
                                default='gpt-4o'
                                style={{ flex: 1 }}
                            >
                                <option value="">Select a model</option>
                                {models.map((modelItem) => (
                                    <option key={modelItem.model} value={modelItem.model}>
                                        {modelItem.model}
                                    </option>
                                ))}
                                <option value="finetuned">Finetuned Model</option>
                            </select>
                            {showCustomModelInput && (
                                <input
                                    type="text"
                                    placeholder="Enter fine-tuned model name"
                                    value={customModelName}
                                    onChange={(e) => setCustomModelName(e.target.value)}
                                    className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                                    style={{ flex: 1 }}
                                />
                            )}
                        </div>
                    </label>
                    
                    {/* The Bools input with Public checkbox included */}
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '40px' }}>
                        {[
                            { key: 'isPublic', displayName: 'Public', tooltip: 'Make this deployment accessible to the public without login' },
                            { key: 'chat', displayName: 'Chat', tooltip: 'Allow chat interface' },
                            { key: 'data', displayName: 'Data', tooltip: 'Allow data table interface' },
                            { key: 'edit', displayName: 'Edit', tooltip: 'Allow the adding and editing of data' },
                            { key: 'del', displayName: 'Delete', tooltip: 'Allow the deleting of data' },
                            { key: 'dark', displayName: 'Dark Mode', tooltip: 'Use Dark mode (Defaults to light mode)' },
                            { key: 'context', displayName: 'Context', tooltip: 'By default, all responses are RAG responses using your Vault. Uncheck if you want to turn off RAG responses and NOT use the data in your Vault as context.' },
                            { key: 'JSN', displayName: 'JSON', tooltip: 'Allow users to download/upload the entire database to/from JSON' }
                        ].map(({ key, displayName, tooltip }) => (
                            <div key={key} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '10px' }}>
                                <label style={{ display: 'flex', alignItems: 'center' }} title={tooltip}>
                                    {displayName}:
                                    <input
                                        type="checkbox"
                                        checked={eval(key)}
                                        onChange={e => {
                                            log(`Setting ${key}: ${e.target.checked}`); 
                                            if (key === 'isPublic') {
                                                setIsPublic(e.target.checked);
                                            } else {
                                                eval(`set${key.charAt(0).toUpperCase() + key.slice(1)}(e.target.checked)`);
                                            }
                                        }}
                                        className={isDarkMode ? 'sleek-checkbox-dark' : 'sleek-checkbox-light'}
                                        style={{ marginLeft: '5px' }}
                                        title={tooltip}
                                    />
                                </label>
                            </div>
                        ))}
                    </div>
                    
                    {/* User Management Component - now only handles the user table, not the Public checkbox */}
                    <UserManagement
                      isDarkMode={isDarkMode}
                      isPublic={isPublic}
                      setIsPublic={setIsPublic}
                      users={users}
                      handleUserChange={handleUserChange}
                      handleAddUser={handleAddUser}
                      handleRemoveUser={handleRemoveUser}
                    />
                    
                    <button className='button' type="submit" style={{ alignSelf: 'start' }}>Deploy</button>
                    <div className="loading-spinner" style={{ display: isLoading ? 'block' : 'none' }}>
                        <svg viewBox="0 0 50 50">
                            <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                        </svg>
                    </div>
                </form>
                <Deployments isDarkMode={isDarkMode} styles={styles} depsBrah={deployments} wait={wait} onEdit={handleEdit} onDelete={handleDelete} />
            </div>
        </div>
    );    
}

export default Deploy;