// Sidebar.js
import React, { useEffect, useState } from 'react';
import logo from '../Vector Vault Icon.png';
import { useNavigate } from 'react-router-dom';
import { billingPortal } from '../api/billingPortal';
import Orientation from '../dashboard/Orient';
import { setCookie } from '../cookieUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function Sidebar({ 
    isDarkMode, 
    setIsDarkMode, 
    styles, 
    sidebarVisible, 
    setSidebarVisible, 
    customerID, 
    isMobile, 
    conversations // <-- new prop passed from main
}) {
    const isLandscape = Orientation('(orientation: landscape)');
    const sidebarClass = isDarkMode ? 'sidebar sidebar-dark' : 'sidebar sidebar-light';
    const navigate = useNavigate();
    const payingCustomer = customerID !== "null";
    const [showConversations, setShowConversations] = useState(false);

    const handleBillingClick = async () => {
        if (!payingCustomer) {
            window.open('https://billing.vectorvault.io/b/7sIeYu1Rr15j0sU8wy', '_blank');
        } else {
            await billingPortal(customerID);
        }
    };

    useEffect(() => {
        if (isMobile) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    }, [isMobile, setSidebarVisible]);

    const toggleIfObble = () => {
        if (isMobile) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    const handleDashboardClick = () => {
        toggleIfObble();
        navigate('/dashboard');
    };

    const handleConversationClick = (uuid) => {
        toggleIfObble();
        navigate(`/dashboard/${uuid}`); // Adjust route as needed
    };

    useEffect(() => {
        const savedMode = localStorage.getItem("isDarkMode");
        if (savedMode) {
            setIsDarkMode(savedMode === 'true');
        }
    }, [setIsDarkMode]);

    const toggleTheme = () => {
        const newState = !isDarkMode;
        setIsDarkMode(newState);
        localStorage.setItem("isDarkMode", newState.toString());
    };

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
        setCookie('sidebarVisible', String(!sidebarVisible));
    };

    const style = isLandscape ? { 
        position: 'fixed', 
        top: '10px', 
        left: sidebarVisible ? '164px' : '0', 
        zIndex: 1000, cursor: 'pointer', 
        transition: 'left 0.3s', 
        transform: 'translateY(-50%)',
        marginLeft: '-8px',
        background: 'transparent', 
        marginTop: 'calc(4%)' 
    } : { 
        position: 'fixed', 
        top: '10px', 
        left: sidebarVisible ? '164px' : '0', 
        zIndex: 1000, cursor: 'pointer', 
        transition: 'left 0.3s', 
        transform: 'translateY(-50%)',
        marginLeft: '-8px',
        background: 'transparent',
        marginTop: 'calc(7%)' 
    };

    return (
        <div className={`sidebar-container ${isMobile && !sidebarVisible ? 'hide-sidebar' : ''}`}>
            <div className={sidebarClass} style={{ ...styles.body, zIndex: 1000, width: '180px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh', padding: '20px', position: sidebarVisible ? 'relative' : 'fixed', left: 0, top: 0 }}>
                <div>
                    <h1 style={{ ...styles.logo, textAlign: "center", fontSize: "1rem", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "8px", paddingRight: "0px", marginLeft: "-6px"}}>
                        <img src={logo} className="App-logo" alt="logo" style={{ height: "3.5rem" }} />
                    </h1>
                    <div style={{ marginTop: "4px", fontSize: "1.2rem", justifyContent: "center", display: "flex", marginLeft: "-6px"}}>Vector Vault</div>
                </div>
                <div className={`sidebar-nav ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
                    <ul>
                        <li onClick={handleDashboardClick}>
                            Dashboard
                        </li>
                        {showConversations && (
                            <div>
                                <FontAwesomeIcon 
                                icon={showConversations ? faChevronUp : faChevronDown} 
                                style={{ marginLeft: '8px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowConversations(!showConversations);
                                }}
                                />
                            
                                <div style={{ maxHeight: '200px', overflowY: 'auto', marginLeft: '10px', marginTop: '5px' }}>
                                    {Object.entries(conversations).map(([title, uuid]) => (
                                        <li 
                                            key={uuid} 
                                            style={{ fontSize: '0.9rem', cursor: 'pointer', padding: '4px 0' }}
                                            onClick={() => handleConversationClick(uuid)}
                                        >
                                            {title}
                                        </li>
                                    ))}
                                </div>
                            </div>
                        )}
                        <li onClick={() => {toggleIfObble(); navigate('/database');}}>Database</li>
                        <li onClick={() => {toggleIfObble(); navigate('/deployments');}}>Deployments</li>
                        <li onClick={() => {toggleIfObble(); navigate('/chat-widgets');}}>Chat Widgets</li>
                        <li onClick={() => {toggleIfObble(); navigate('/vector-flow');}}>Vector Flow</li>
                        <li onClick={() => {toggleIfObble(); navigate('/vaults');}}>Vaults</li>
                        <li onClick={() => {toggleIfObble(); navigate('/usage');}}>Usage</li>
                        <li>
                            {payingCustomer ? 
                                <a className="no-style-link" href="#" onClick={handleBillingClick}>Billing</a> : 
                                <a className="no-style-link" href="#" onClick={handleBillingClick}>SignUp</a>
                            }
                        </li>
                        <li onClick={() => {toggleIfObble(); navigate('/apikeys');}}>API Keys</li>
                        <li onClick={() => {toggleIfObble(); navigate('/settings');}}>Settings</li>
                    </ul>
                </div>
                <div className="sidebar-footer">
                    <button className={`toggle-button ${isDarkMode ? "dark-mode" : ""}`} onClick={toggleTheme}>
                        {isDarkMode ? "Dark Mode" : "Light Mode"}
                    </button>
                </div>
            </div>
            {isMobile && (
                <div 
                    className={isDarkMode ? "sidebar-tab-dark" : "sidebar-tab-light"} 
                    onClick={toggleSidebar} 
                    style={{ ...style }}
                >
                    <FontAwesomeIcon icon={faBars} />
                </div>
            )}
        </div>
    );
}

export default Sidebar;