// styles.js
export const darkStyles = {
  wrapper: {
    backgroundColor: "#282c34",
    minHeight: "100vh",
  },
  userCredits: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "#474E5C",
    padding: "8px 16px",
    borderRadius: "4px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  },
  body: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#fff",
    backgroundColor: "#282c34",
    padding: "20px",
    textAlign: "left",
  },
  refresh: {
    fontFamily: "sans-serif",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#787878",
    backgroundColor: "#282c34",
    paddingLeft: "4px",
    textAlign: "left",
  },
  h1: {
    marginTop: "0",
  },
  label: {
    display: "block",
    marginBottom: "8px",
    marginLeft: "-4px",
    fontWeight: "bold",
  },
  yourmsgLabel: {
    display: "block",
    fontSize: "1.3rem",
    marginTop: "30px",
    marginBottom: "8px",
    fontWeight: "bold",
  },
  textarea: {
    width: "100%",
    fontFamily: "monospace",
    color: "#fff",
    backgroundColor: "#313640", // changed background color to #b8bdb5
    border: "1px solid #626C80",
    borderRadius: "4px",
    padding: "8px",
    resize: "vertical",
    fontSize: "1rem",
    paddingLeft: "0px",
  },
  button: {
    backgroundColor: "#0080FF", // Celestial Blue: 009FFD, Azure - Original: 0080FF
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "8px 16px",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "16px",
    marginBottom: "10px",
    transition: "background-color 0.3s",
  },
  btn: {
    background: 'none',
    border: 'none',
    padding: '3px 10px', // Maintains the clickable area
    fontSize: '1rem',
    color: '#ddd', // Change color as needed
    cursor: 'pointer',
    textAlign: 'center',
    marginLeft: '5px',
    marginBottom: '2px',
    textDecoration: 'none', // No underline
    display: 'inline-block'
  },
  bttn: {
    padding: '3px 10px',
    fontSize: '1rem',
    color: '#white', // A lighter shade for text to stand out
    backgroundColor: '#424857', // A distinct dark shade different from input background
    border: '1px solid #666', // A slightly lighter border
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    textAlign: 'center',
    display: 'inline-block',
    marginLeft: '5px',
    marginBottom: '2px',
    textDecoration: 'none', // No underline
  },
  sleekInput: {
    border: '1px solid #555', // Lighter border for dark mode
    color: '#fff', // Lighter text color for dark mode
    backgroundColor: '#32363f', // Dark background for the input
  },
  apiInput: {
    flexGrow: 1,
    margin: '0 15px',
    maxWidth: '420px',
    border: '1px solid #555', // Lighter border color for visibility in dark mode
    padding: '4px',
    borderRadius: '4px',
    outline: 'none',
    color: '#fff', // Lighter text color for dark mode
    backgroundColor: '#32363f'
  },
  buttonHover: {
    backgroundColor: "#0069d9",
  },
  responseContainer: {
    marginTop: "60px",
    marginBottom: "20px",
    marginRight: "-8px",
    marginLeft: "-8px",
    padding: "20px 6px",
    paddingBottom: "20px",
    borderRadius: "4px",
    backgroundColor: "#474E5C",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "left",
  },
  chatResponseContainer: {
    marginTop: "16px",
    marginBottom: "150px",
    marginLeft: "-8px",
    padding: "20px",
    paddingBottom: "10px",
    borderRadius: "4px",
    backgroundColor: "#474E5C",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "left",
  },
  responseContainerShow: {
    display: "block",
  },
  responseContainerH3: {
    marginTop: "0px",
    fontSize: "18px",
  },
  response: {
    whiteSpace: "pre-wrap",
    fontFamily: "monospace",
    fontSize: "16px",
    marginTop: "8px",
    marginBottom: "8px",
    color: "#fff",
  },
  logo: {
    whiteSpace: "pre-wrap",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    marginTop: "8px",
    marginBottom: "8px",
    color: "#fff",
  },
  loadingSpinner: {
    display: "none",
    marginTop: "16px",
    textAlign: "center",
  },
  loadingSpinnerSvg: {
    width: "30px",
    height: "30px",
  },
  loadingSpinnerCircle: {
    strokeDasharray: "113",
    strokeDashoffset: "113",
    transformOrigin: "center",
    animation: "spin 1s linear infinite, draw 1s linear infinite",
  },
  userMessage: {
    textAlign: 'left',
    fontSize: "1rem",
    backgroundColor: "#565B66",
    borderRadius: '5px',
    color: '#99ccff', // Light blue color for user messages in dark mode
    marginBottom: '0.5rem',
    marginLeft: "4px",
    padding: "8px",
    marginRight: '-8px',
    whiteSpace: 'pre-wrap',
  },
  notificationStyle: {
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    backgroundColor: "#474E5C",
    color: "#fff",
    fontWeight: 'bold',
    position: 'fixed',
    top: '120px',
    right: '20px',
    zIndex: 999,
  },
  botMessage: {
    textAlign: 'left',
    fontSize: "1rem",
    color: '#e6e6e6', // Light gray color for bot messages in dark mode
    marginBottom: '0.5rem',
    marginRight: '-6px',
    padding: "8px 4px",
    whiteSpace: 'pre-wrap',
  },
  chatResponse: {
    borderRadius: "4px",
    marginBottom: "8px",
    padding: "8px",
    fontFamily: "monospace",
    fontSize: "1.1rem",
    backgroundColor: "#b5bad0",
    whiteSpace: 'pre-wrap',
  },
};
export const lightStyles = {
  wrapper: {
    backgroundColor: "#fff",
    minHeight: "100vh",
  },
  userCredits: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#686868",
    backgroundColor: "#fff",
    padding: "8px 16px",
    borderRadius: "4px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  },
  body: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#333",
    backgroundColor: "#f7f7f7",
    padding: "20px",
    textAlign: "left",
  },
  refresh: {
    fontFamily: "sans-serif",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#787878",
    backgroundColor: "#f7f7f7",
    paddingLeft: "4px",
    textAlign: "left",
  },
  h1: {
    marginTop: "0",
  },
  label: {
    display: "block",
    marginBottom: "8px",
    fontWeight: "bold",
  },
  yourmsgLabel: {
    display: "block",
    fontSize: "1.3rem",
    marginTop: "30px",
    marginBottom: "10px",
    fontWeight: "bold",
  },
  textarea: {
    width: "100%",
    fontFamily: "monospace",
    color: "#333",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "8px",
    resize: "vertical",
    fontSize: "1rem",
    paddingLeft: "0px",
  },
  button: {
    backgroundColor: "#0080FF", // Celestial Blue: 009FFD, Azure - Original: 0080FF
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "8px 16px",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "16px",
    marginBottom: "10px",
    transition: "background-color 0.3s",
  },
  btn: {
    background: 'none',
    border: 'none',
    padding: '3px 10px', // Maintains the clickable area
    fontSize: '1rem',
    color: '#444', // Change color as needed
    cursor: 'pointer',
    textAlign: 'center',
    marginLeft: '5px',
    marginBottom: '2px',
    textDecoration: 'none', // No underline
    display: 'inline-block'
  },
  bttn: {
    padding: '3px 10px',
    fontSize: '1rem',
    color: '#444',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
    textAlign: 'center',
    display: 'inline-block',
    marginLeft: '5px',
    marginBottom: '2px',
    textDecoration: 'none', 
  },
  sleekInput: {
    border: '1px solid #ccc', // Lighter border for dark mode
  },
  apiInput: {
    flexGrow: 1,
    margin: '0 15px',
    maxWidth: '420px',
    border: '1px solid #ddd', 
    padding: '4px',
    borderRadius: '4px',
    outline: 'none',
  },
  buttonHover: {
    backgroundColor: "#0069d9",
  },
  responseContainer: {
    marginTop: "60px",
    marginBottom: "20px",
    marginRight: "-8px",
    marginLeft: "-8px",
    padding: "20px 6px",
    paddingBottom: "20px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "left",
  },
  chatResponseContainer: {
    marginTop: "16px",
    marginBottom: "50px",
    marginLeft: "-8px",
    padding: "20px 6px",
    paddingBottom: "10px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "left",
  },
  responseContainerShow: {
    display: "block",
  },
  responseContainerH3: {
    marginTop: "0",
    fontSize: "18px",
  },
  response: {
    whiteSpace: "pre-wrap",
    fontFamily: "monospace",
    fontSize: "16px",
    marginTop: "8px",
    marginBottom: "8px",
    color: "#333",
  },
  logo: {
    whiteSpace: "pre-wrap",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    marginTop: "8px",
    marginBottom: "8px",
    color: "#333",
  },
  notificationStyle: {
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    backgroundColor: "#fff",
    color: "#686868",
    fontWeight: 'bold',
    position: 'fixed',
    top: '120px',
    right: '20px',
    zIndex: 999,
  },
  loadingSpinner: {
    display: "none",
    marginTop: "16px",
    textAlign: "center",
  },
  loadingSpinnerSvg: {
    width: "30px",
    height: "30px",
  },
  loadingSpinnerCircle: {
    strokeDasharray: "113",
    strokeDashoffset: "113",
    transformOrigin: "center",
    animation: "spin 1s linear infinite, draw 1s linear infinite",
  },
  userMessage: {
    textAlign: 'left',
    fontSize: "1rem",
    padding: "8px",
    marginLeft: "4px",
    marginRight: "4px",
    width: 'calc(100% - 8px);%',
    backgroundColor: "#f7f7f7",
    borderRadius: '5px',
    color: '#6394e3', // 719DE3 is old standard #4682B4 is new standard
    marginBottom: '0.5rem',
    whiteSpace: 'pre-wrap',
  },
  botMessage: {
    textAlign: 'left',
    fontSize: "1rem",
    padding: "8px 4px",
    marginBottom: '0.5rem',
    whiteSpace: 'pre-wrap',
  },
chatResponse: {
  borderRadius: "4px",
  marginBottom: "8px",
  padding: "8px",
  fontFamily: "monospace",
  fontSize: "1.1rem",
  backgroundColor: "#f7f7f7",
  whiteSpace: 'pre-wrap',
  },
};