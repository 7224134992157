import React, { useState, useEffect } from 'react';
import "../dashboard/Dashboard.css";
import { log } from "../utils/logger.js"

function WidgetDeployments({ isDarkMode, styles, depsBrah, wait, onEdit, onDelete, onShowScript }) {
    const [deployments, setDeployments] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    log(depsBrah);
    
    // Update deployments whenever depsBrah changes
    useEffect(() => {
        if (depsBrah) {
            setDeployments(depsBrah);
        }
    }, [depsBrah]);

    // Find widget deployments by key prefixes OR type property
    const getWidgetDeployments = () => {
        return Object.entries(deployments).filter(([key, value]) => {
            return key.startsWith('widget:') || 
                  (value && value.type === 'widget') ||
                  (value && value.flow_name); // Also check for flow_name which is specific to widgets
        });
    };
    
    const widgetDeployments = getWidgetDeployments();
    
    // Filter deployments based on search term
    const filteredDeployments = widgetDeployments.filter(([key, value]) => {
        if (!value) return false;
        
        const searchStr = searchTerm.toLowerCase();
        const title = value.title || value.TITLE || key.replace('widget:', '');
        const description = value.description || value.DESCRIPTION || '';
        const flow = value.flow_name || value.FLOWNAME || value.VAULT || '';
        
        return (
            title.toLowerCase().includes(searchStr) || 
            description.toLowerCase().includes(searchStr) || 
            flow.toLowerCase().includes(searchStr)
        );
    });

    const getDisplayValue = (deployment, key) => {
        // Handle case when deployment is missing
        if (!deployment) {
            return {
                displayTitle: key,
                description: 'No description',
                flow: 'No flow'
            };
        }
        
        // Extract title - try multiple possible field names
        let displayTitle = key;
        if (key.startsWith('widget:')) {
            displayTitle = key.substring(7);
        }
        if (deployment.title) {
            displayTitle = deployment.title;
        } else if (deployment.TITLE) {
            displayTitle = deployment.TITLE;
        }
        
        // Extract description
        const description = deployment.description || deployment.DESCRIPTION || 'No description';
        
        // Extract flow name
        const flow = deployment.flow_name || deployment.FLOWNAME || deployment.VAULT || 'No flow selected';
        
        return { displayTitle, description, flow };
    };

    return (
        <div style={{ marginTop: '30px' }}>
            {/* Debug info - remove in production */}
            <div style={{ display: 'none' }}>
                <p>Total deployments: {Object.keys(deployments).length}</p>
                <p>Widget deployments: {widgetDeployments.length}</p>
                <p>Filtered deployments: {filteredDeployments.length}</p>
            </div>
            
            {/* Search bar */}
            <div style={{ marginBottom: '20px' }}>
                <input
                    type="text"
                    placeholder="Search deployments..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                    style={{ width: '100%', padding: '10px' }}
                />
            </div>
            
            {/* Deployments table */}
            <div style={{ overflowX: 'auto' }}>
                <table style={{ 
                    width: '100%', 
                    borderCollapse: 'collapse',
                    backgroundColor: isDarkMode ? '#1a1a1a' : '#ffffff',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
                }}>
                    <thead>
                        <tr style={{ 
                            backgroundColor: isDarkMode ? '#333' : '#f5f5f5',
                            color: isDarkMode ? '#fff' : '#333'
                        }}>
                            <th style={{ padding: '12px', textAlign: 'left' }}>Title</th>
                            <th style={{ padding: '12px', textAlign: 'left' }}>Description</th>
                            <th style={{ padding: '12px', textAlign: 'left' }}>Flow</th>
                            <th style={{ padding: '12px', textAlign: 'center' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDeployments.length > 0 ? (
                            filteredDeployments.map(([key, deployment], index) => {
                                const { displayTitle, description, flow } = getDisplayValue(deployment, key);
                                
                                return (
                                    <tr key={key} style={{ 
                                        backgroundColor: index % 2 === 0 
                                            ? (isDarkMode ? '#262626' : '#f9f9f9') 
                                            : (isDarkMode ? '#1f1f1f' : '#ffffff'),
                                        borderBottom: `1px solid ${isDarkMode ? '#333' : '#eee'}`
                                    }}>
                                        <td style={{ padding: '12px', color: isDarkMode ? '#fff' : '#333' }}>
                                            {displayTitle}
                                        </td>
                                        <td style={{ padding: '12px', color: isDarkMode ? '#ddd' : '#555' }}>
                                            {description}
                                        </td>
                                        <td style={{ padding: '12px', color: isDarkMode ? '#ddd' : '#555' }}>
                                            {flow}
                                        </td>
                                        <td style={{ padding: '12px', textAlign: 'center' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                                <button
                                                    onClick={() => onEdit(deployment)}
                                                    className="table-action-button"
                                                    style={{
                                                        backgroundColor: isDarkMode ? '#2c5282' : '#ebf4ff',
                                                        color: isDarkMode ? '#fff' : '#3182ce',
                                                        border: 'none',
                                                        padding: '6px 12px',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer',
                                                        fontSize: '14px'
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    onClick={() => onShowScript(deployment)}
                                                    className="table-action-button"
                                                    style={{
                                                        backgroundColor: isDarkMode ? '#234e52' : '#e6fffa',
                                                        color: isDarkMode ? '#fff' : '#319795',
                                                        border: 'none',
                                                        padding: '6px 12px',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer',
                                                        fontSize: '14px'
                                                    }}
                                                >
                                                    Show Script
                                                </button>
                                                <button
                                                    onClick={() => onDelete(key)}
                                                    className="table-action-button"
                                                    style={{
                                                        backgroundColor: isDarkMode ? '#742a2a' : '#fff5f5',
                                                        color: isDarkMode ? '#fff' : '#e53e3e',
                                                        border: 'none',
                                                        padding: '6px 12px',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer',
                                                        fontSize: '14px'
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td 
                                    colSpan="4" 
                                    style={{ 
                                        padding: '20px', 
                                        textAlign: 'center',
                                        color: isDarkMode ? '#bbb' : '#666'
                                    }}
                                >
                                    {searchTerm 
                                        ? 'No deployments match your search.' 
                                        : 'No widget deployments found. Create your first widget above!'}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            
            {/* Deployment count */}
            <div style={{ 
                marginTop: '10px', 
                textAlign: 'right',
                fontSize: '0.9em',
                color: isDarkMode ? '#bbb' : '#666'
            }}>
                Total: {filteredDeployments.length} widget{filteredDeployments.length !== 1 ? 's' : ''}
            </div>
        </div>
    );
}

export default WidgetDeployments;