import React, {
  useState,
  forwardRef,
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { fetchModelList } from '../api/modelAPI.js';
import { AceEditorComponent } from './AceSpaceBro.js';
import { RenderCopyToClipboardButton } from './copyclip.js';
import DataTable from './ChatDataTable.js';
import { getCookie, setCookie } from '../cookieUtils.js';
import './Dashboard.css';
import { AuthContext } from '../App.js';
import AdvancedControls from '../components/AdvancedControls.jsx';
import MarkdownRenderer from '../utils/markDownRenderer.js';

export function ChatContainer(
  {
    isDarkMode,
    isMobile,
    windowWidth,
    Ampersandposium,
    display,
    reloadData,
    setReloadData,
    showDelete,
    userEmail,
    wait,
    styles,
    setNotificationMessage,
  },
  ref
) {
  const { vectorVault } = useContext(AuthContext);

  // =======================
  // State variables
  // =======================
  const [chatMessage, setChatMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isChatLoading, setChatIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [getContext, setGetContext] = useState(getCookie('getContext') === 'true');
  const [nContext, setNContext] = useState(4);
  const [historySearch, setHistorySearch] = useState(true);
  const [embeddings, setEmbeddings] = useState('text-embedding-3-small');

  // Model handling
  const [model, setModel] = useState(getCookie('model') || 'gpt-4o');
  const [prevModel, setPrevModel] = useState(getCookie('model') || 'gpt-4o');
  const [customModelName, setCustomModelName] = useState('');
  const [showCustomModelInput, setShowCustomModelInput] = useState(model === 'finetuned');
  const [models, setModels] = useState([]);

  // Flow
  const [isFlow, setIsFlow] = useState(false);
  const [vaultName, setVaultName] = useState('');

  // Misc
  const [tempMessage, setTempMessage] = useState('');
  const [scrolls, setScrolls] = useState(0);
  const messageEndRef = useRef(null);

  // Track the full text of the last chunk to prevent repeated data
  const [lastChunk, setLastChunk] = useState('');

  // For streaming code blocks
  const [streamingCodeBlocks, setStreamingCodeBlocks] = useState({});

  // =======================
  // Scroll to bottom logic
  // =======================
  const scrollToBottom = useCallback(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (scrolls === 0) {
      scrollToBottom();
      setScrolls(1);
    }
  }, [chatHistory, scrollToBottom]);

  // =======================
  // Flow detection
  // =======================
  const isFlowState = useMemo(() => {
    if (Ampersandposium) {
      return Ampersandposium.startsWith('Flow: ');
    }
    return false;
  }, [Ampersandposium]);

  useEffect(() => {
    if (isFlowState) {
      setIsFlow(true);
      setVaultName(Ampersandposium.substring('Flow: '.length));
    } else {
      setIsFlow(false);
      setVaultName(Ampersandposium);
    }
  }, [isFlowState, Ampersandposium]);

  // =======================
  // Cookies & persistence
  // =======================
  useEffect(() => {
    setCookie('getContext', getContext);
  }, [getContext]);

  // Lazy-load chat history from localStorage
  useEffect(() => {
    const loadChatHistory = async () => {
      const storedChatHistory = localStorage.getItem('chatHistory');
      if (storedChatHistory) {
        try {
          const parsedHistory = JSON.parse(storedChatHistory);
          const CHUNK_SIZE = 20;

          for (let i = 0; i < parsedHistory.length; i += CHUNK_SIZE) {
            const chunk = parsedHistory.slice(i, i + CHUNK_SIZE);
            setChatHistory((prev) => [...prev, ...chunk]);
            // Small delay to prevent UI blocking
            await new Promise((resolve) => setTimeout(resolve, 0));
          }
        } catch (error) {
          console.error('Failed to parse stored chat history:', error);
          setChatHistory([]);
        }
      }
    };
    loadChatHistory();
  }, []);

  // Debounced chat history save
  useEffect(() => {
    const saveTimeout = setTimeout(() => {
      if (chatHistory.length > 0) {
        localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
      }
    }, 1000);

    return () => clearTimeout(saveTimeout);
  }, [chatHistory]);

  // Load model list from API
  useEffect(() => {
    const loadModels = async () => {
      try {
        const fetchedModels = await fetchModelList(userEmail);
        setModels(fetchedModels);
      } catch (error) {
        console.error('Failed to fetch models:', error);
      }
    };
    loadModels();
  }, [userEmail]);

  // =======================
  // Model handling
  // =======================
  const handleModelChange = useCallback((e) => {
    const selectedModel = e.target.value;
    setModel(selectedModel);
    setCookie('model', selectedModel);
    setShowCustomModelInput(selectedModel === 'finetuned');
    if (selectedModel !== 'finetuned') {
      setCustomModelName('');
    }
  }, []);

  const getSelectedModel = useCallback(() => {
    return model === 'finetuned' ? customModelName.trim() : model;
  }, [model, customModelName]);

  // =======================
  // Rendering chat messages
  // =======================
  const renderChatSegment = useCallback(
    (segment, index, isUserMessage) => {
      // Check if this segment includes a complete code block
      const isFullCodeBlock = /```[\s\S]*?```/.test(segment);

      // Handle streaming blocks that aren't complete yet
      if (streamingCodeBlocks[index] && !isFullCodeBlock) {
        const { language, content, blockStart } = streamingCodeBlocks[index];
        const textBeforeBlock = segment.slice(0, blockStart);

        return (
          <div className="message-segments">
            {textBeforeBlock && (
              <div
                className="message-content"
                style={{
                  ...(isUserMessage ? styles.userMessage : styles.botMessage),
                  margin: '8px 0',
                }}
              >
                {textBeforeBlock}
              </div>
            )}
            <div
              style={{
                position: 'relative',
                width: `${100 + (1 / (windowWidth / 500)) * 5}%`,
                marginLeft: '-4px',
              }}
            >
              <AceEditorComponent
                key={`editor-${index}-${content.length}`}
                code={content}
                type={language || 'python'}
                isDarkMode={isDarkMode}
                isStreaming={true}
              />
            </div>
          </div>
        );
      }

      // Process completed code blocks
      const parts = [];
      let currentPos = 0;
      // Regex to capture triple-backtick code blocks: ```(language)?\n?(content)```
      const codeBlockRegex = /```(\w+)?\n?([\s\S]*?)```/g;
      let match;

      while ((match = codeBlockRegex.exec(segment)) !== null) {
        if (match.index > currentPos) {
          parts.push({
            type: 'text',
            content: segment.slice(currentPos, match.index),
          });
        }

        parts.push({
          type: 'code',
          language: match[1] || 'python',
          content: match[2],
        });

        currentPos = match.index + match[0].length;
      }

      if (currentPos < segment.length) {
        parts.push({
          type: 'text',
          content: segment.slice(currentPos),
        });
      }

      if (parts.length === 0) {
        parts.push({
          type: 'text',
          content: segment,
        });
      }

      return (
        <div className="message-segments">
          {parts.map((part, partIndex) => {
            if (part.type === 'code') {
              return (
                <div
                  key={`${index}-${partIndex}`}
                  style={{
                    position: 'relative',
                    width: `${100 + (1 / (windowWidth / 500)) * 5}%`,
                    marginLeft: '-4px',
                    marginTop: '20px',
                  }}
                >
                  <AceEditorComponent
                    key={`static-editor-${index}-${partIndex}`}
                    code={part.content}
                    type={part.language}
                    isDarkMode={isDarkMode}
                    isStreaming={false}
                  />
                  {RenderCopyToClipboardButton(
                    part.content,
                    isDarkMode,
                    isCopied,
                    setIsCopied,
                    () => {
                      setIsCopied(true);
                      setTimeout(() => setIsCopied(false), 300);
                    }
                  )}
                </div>
              );
            }

            // Some text may contain Markdown, so we detect that and render accordingly
            const containsMarkdown = /(^|\n)#{1,6}\s|^\s*[-*]\s|\*\*/.test(part.content);
            return (
              <div
                key={`${index}-${partIndex}`}
                className={`message-content ${containsMarkdown ? 'markdown-content' : ''}`}
                style={{
                  ...(isUserMessage ? styles.userMessage : styles.botMessage),
                }}
              >
                {containsMarkdown ? (
                  <MarkdownRenderer content={part.content} />
                ) : (
                  part.content
                )}
              </div>
            );
          })}
        </div>
      );
    },
    [windowWidth, isDarkMode, isCopied, styles, streamingCodeBlocks]
  );

  const renderChatMessage = useCallback(
    (message, isUserMessage, index) => {
      return (
        <div key={index}>
          {renderChatSegment(message, index, isUserMessage)}
        </div>
      );
    },
    [renderChatSegment]
  );

  // =======================
  // Sending messages
  // =======================
  const appendChatMessage = useCallback((message, isUserMessage) => {
    if (!message.trim()) return; // Don't add empty messages

    setChatHistory((prev) => {
      const newHistory = [...prev, { message, isUserMessage }];
      localStorage.setItem('chatHistory', JSON.stringify(newHistory));
      return newHistory;
    });
  }, []);

  const sendChatMessage = async () => {
    // 1. Check if the current chat message is empty
    if (!chatMessage.trim()) return;

    // 2. Preserve the current message and reset state
    const pastChatMessage = chatMessage;
    setChatMessage('');
    setChatIsLoading(true);
    setTempMessage('');

    // 3. Immediately add the user's message to chat UI
    appendChatMessage(pastChatMessage, true);

    // 4. Check if message ends with '**'
    const messageConfig = pastChatMessage.trim().endsWith('**')
      ? {
          metatag: ['item_id'],
          metatag_prefixes: ['\n**Item ID:'],
          metatag_suffixes: ['\n'],
          returnContext: true,
        }
      : {
          metatag: [],
          metatag_prefixes: [],
          metatag_suffixes: [],
          returnContext: false,
        };

    // 5. Compile conversation history
    const fullHistory = chatHistory
      .map((item) =>
        item.isUserMessage ? `User: ${item.message}` : `Response: ${item.message}`
      )
      .join('\n');

    // 6. Send to either flow or normal chat
    try {
      if (isFlow) {
        await handleFlowMessage(pastChatMessage, fullHistory);
      } else {
        await handleChatMessage(pastChatMessage, fullHistory, messageConfig);
      }
    } catch (error) {
      appendChatMessage(error.message, false);
      setChatIsLoading(false);
    }
  };

  // =======================
  // Flow & Chat handlers
  // =======================
  const handleFlowMessage = async (message, history) => {
    await vectorVault.runFlowStream(vaultName, message, history, {
      onMessage: handleStreamMessage,
      onLog: (logData) => console.log('Log:', logData),
      onError: handleError,
    });
  };

  const handleChatMessage = async (message, history, config) => {
    const params = {
      vault: vaultName,
      text: message,
      history,
      get_context: getContext,
      n_context: nContext,
      return_context: config.returnContext,
      smart_history_search: historySearch,
      model: getSelectedModel(),
      embeddings_model: embeddings,
      ...config,
    };

    await vectorVault.getChatStream(params, handleStreamMessage);
  };

  // =======================
  // Streaming callbacks
  // =======================
  const handleError = useCallback(
    (error) => {
      appendChatMessage(error.message, false);
      localStorage.setItem(
        'chatHistory',
        JSON.stringify([...chatHistory, { message: error.message, isUserMessage: false }])
      );
      setChatIsLoading(false);
    },
    [chatHistory, appendChatMessage]
  );

  const handleStreamMessage = useCallback(
    (dataChunk) => {
      // 1) Check if the server signals end of stream
      if (dataChunk === '!END') {
        setTempMessage('');
        setChatIsLoading(false);
        setLastChunk(''); // Reset last chunk

        // Clear streaming blocks if we have a complete code block
        setChatHistory((prev) => {
          const lastMsg = prev[prev.length - 1];
          if (lastMsg && /```[\s\S]*?```/.test(lastMsg.message)) {
            setStreamingCodeBlocks({});
          }
          return prev;
        });

        return;
      }

      // 2) If the server re-sends the entire text so far, slice off the repeated portion
      let chunkToAppend = dataChunk;
      if (dataChunk.startsWith(lastChunk)) {
        chunkToAppend = dataChunk.slice(lastChunk.length);
      }
      setLastChunk(dataChunk);

      // 3) Append only the new part of the chunk
      setChatHistory((prev) => {
        const lastIndex = prev.length - 1;
        const isLastBotMsg = prev.length > 0 && !prev[lastIndex].isUserMessage;

        const updatedMessage = isLastBotMsg
          ? prev[lastIndex].message + chunkToAppend
          : chunkToAppend;

        // Optional code-block streaming logic
        // If you’re modifying partial code blocks, do so here using updatedMessage.
        // See your existing code for streaming code blocks.

        // Final update to the chat history
        if (isLastBotMsg) {
          return [
            ...prev.slice(0, -1),
            { message: updatedMessage, isUserMessage: false },
          ];
        } else {
          return [...prev, { message: updatedMessage, isUserMessage: false }];
        }
      });
    },
    [lastChunk]
  );

  // =======================
  // Utilities
  // =======================
  const clearChat = useCallback(() => {
    setChatHistory([]);
    localStorage.removeItem('chatHistory');
  }, []);

  const handleTextareaKeydown = useCallback(
    (e) => {
      // Ctrl+Enter or Cmd+Enter to send
      if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        sendChatMessage();
      }
    },
    [sendChatMessage]
  );

  // =======================
  // Render
  // =======================
  return (
    <div
      className="response-container"
      id="chat-container"
      style={{
        ...styles.responseContainer,
        minHeight: '200px',
        display: 'flex',
        padding: isMobile ? '20px 6px' : '20px',
        height: 'auto',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {/* Header with clear button */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <button
          id="clear-chat"
          className="link-button"
          onClick={clearChat}
          style={{ marginRight: isMobile ? '8px' : '2px' }}
        >
          Clear
        </button>
      </div>

      {/* Chat history */}
      <div style={{ width: '100%', marginBottom: '20px' }}>
        {chatHistory.map((chat, index) =>
          renderChatMessage(chat.message, chat.isUserMessage, index)
        )}
        {isChatLoading && tempMessage && renderChatMessage(tempMessage, false, chatHistory.length)}
        <div ref={messageEndRef} />
      </div>

      {/* Loading spinner */}
      {isChatLoading && (
        <div
          className="loading-spinner"
          id="loading-spinner"
          style={{
            ...styles.loadingSpinner,
            display: 'block',
            paddingLeft: '20px',
          }}
        >
          <svg viewBox="0 0 50 50" style={styles.loadingSpinnerSvg}>
            <circle
              cx="25"
              cy="25"
              r="20"
              stroke="#007aff"
              strokeWidth="5"
              fill="none"
              style={styles.loadingSpinnerCircle}
            />
          </svg>
        </div>
      )}

      {/* Input area */}
      <div style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ ...styles.yourmsgLabel }}>
            <label htmlFor="chat-message">Chat:</label>
          </div>
          {!isFlow && (
            <>
              <select
                style={{ marginLeft: '10px', marginTop: '20px' }}
                className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                value={model}
                onChange={handleModelChange}
              >
                {models.map((modelItem) => (
                  <option key={modelItem.model} value={modelItem.model}>
                    {modelItem.model}
                  </option>
                ))}
                <option value="finetuned">Finetuned Model</option>
              </select>
              {showCustomModelInput && (
                <input
                  type="text"
                  placeholder="Enter fine-tuned model name"
                  value={customModelName}
                  onChange={(e) => setCustomModelName(e.target.value)}
                  style={{ ...styles.apiInput, marginLeft: '10px', marginTop: '20px' }}
                />
              )}
            </>
          )}

          {!isFlow && (
            <AdvancedControls
              isDarkMode={isDarkMode}
              getContext={getContext}
              setGetContext={setGetContext}
              nContext={nContext}
              setNContext={setNContext}
              historySearch={historySearch}
              setHistorySearch={setHistorySearch}
              embeddings={embeddings}
              setEmbeddings={setEmbeddings}
              styles={styles}
            />
          )}
        </div>
      </div>
      

      <textarea
        placeholder="Type your message here..."
        id="chat-message"
        rows={isMobile ? '8' : '5'}
        style={{ ...styles.textarea, paddingLeft: '8px' }}
        value={chatMessage}
        onChange={(e) => setChatMessage(e.target.value)}
        onKeyDown={handleTextareaKeydown}
      />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <button id="chat-submit" style={{ ...styles.button }} onClick={sendChatMessage}>
          Send
        </button>
      </div>

      <div style={{ marginTop: '100px' }}></div>

      {/* Optional DataTable if needed */}
      {display && !isFlow && (
        <DataTable
          Ampersandposium={Ampersandposium}
          reloadData={reloadData}
          setReloadData={setReloadData}
          showDelete={showDelete}
          userEmail={userEmail}
          isDarkMode={isDarkMode}
          wait={wait}
          styles={styles}
        />
      )}
    </div>
  );
}

export default forwardRef(ChatContainer);
