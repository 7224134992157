import React, { useState, useEffect } from 'react';
import { lightStyles, darkStyles } from '../styles';

function Usage({ isDarkMode, usage, plan, handleCredGet }) {
    const styles = isDarkMode ? darkStyles : lightStyles
    const showPlan = typeof plan === 'string' && plan.length > 0
        ? plan.charAt(0).toUpperCase() + plan.slice(1)
        : plan;
    const [limits, setLimits] = useState({});

    useEffect(() => {
        setLimits({
            pro: 100000,
            business: 1000000,
            enterprise: 100000000
        });
    }, []);

    // Calculate the usage percentage
    const usagePercentage = (usage / limits[plan]) * 100;

    // Styles
    const progress = {
        progressBar: {
            marginTop: '20px',
            height: '50px', // Increased height
            backgroundColor: isDarkMode ? '#313640' : '#e0e0e0',
            borderRadius: '5px',
            overflow: 'hidden'
        },
        progress: {
            height: '100%',
            width: `${usagePercentage}%`,
            backgroundColor: '#0080FF', // Blue color for progress
            borderRadius: '5px',
            textAlign: 'center',
            fontSize: '1.2rem',
            lineHeight: '50px', // Adjusted to match the new height
            color: 'white'
        },
    };

    return (
            <div className="App" style={{ ...styles.body, paddingTop: "50px" }}>
                <h1 style={{marginTop: "50px"}}>Usage</h1>
                <div style={progress.progressBar}>
                    <div style={progress.progress}>{`${usagePercentage.toFixed(2)}%`}</div>
                </div>
                    <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>Plan: <strong>{showPlan}</strong></h3>
                    <p>You have used <strong>{usage}</strong> out of <strong>{limits[plan]}</strong> requests.</p>
                    <div>
                        <a href="#" onClick={(e) => { e.preventDefault(); handleCredGet(); }}>Refresh</a>
                    </div>
            </div>
    );
}

export default Usage;