import React from "react";
import WidgetDeployments from "./WidgetDeploymentsTables";
import { ChromePicker } from "react-color";
import WidgetDeployLogic from "./WidgetDeployLogic";

export default function WidgetDeploy(props) {
  const {
    isDarkMode,
    styles,
    isLoading,
    isEditing,
    isWidgetDeployed,
    widgetScript,
    deployments,
    flows,

    title, setTitle,
    titleError,
    description, setDescription,
    flow, setFlow,

    primaryColor, setPrimaryColor,
    headerColor, setHeaderColor,
    userBubbleColor, setUserBubbleColor,
    assistantBubbleColor, setAssistantBubbleColor,
    backgroundGradientFrom, setBackgroundGradientFrom,
    backgroundGradientTo, setBackgroundGradientTo,

    headerTextColor, setHeaderTextColor,
    userTextColor, setUserTextColor,
    assistantTextColor, setAssistantTextColor,

    headerIcon, setHeaderIcon,
    headerTitle, setHeaderTitle,
    initialMessage, setInitialMessage,
    stockReply, setStockReply,

    showColorPanel,
    toggleColorPanel,

    handleEdit,
    handleDelete,
    handleTitleChange,
    copyScriptToClipboard,
    resetToDefaults,
    handleSubmit,
    handleShowScript,
    copyButtonText
  } = WidgetDeployLogic(props);

  // Decide card container classes based on dark mode
  const cardClass = `widget-card ${isDarkMode ? 'widget-card-dark' : ''}`;
  const previewClass = `widget-preview ${isDarkMode ? 'widget-preview-dark' : ''}`;

  return (
    <div style={styles.wrapper}>
      <div className="App" style={{ ...styles.body, paddingTop: "50px" }}>
        <h1 style={{ ...styles.h1, marginBottom: '0.5rem' }}>Chat Widget Deployment</h1>
        <p style={{ fontSize: '0.95rem', lineHeight: '1.5' }}>
          Create customizable chat widgets for your website. Each deployment is $10/mo. Click deploy. Copy the script. Paste on your website.
        </p>

        {/* MAIN CARD */}
        <div className={cardClass}>
          <form onSubmit={handleSubmit} className="widget-form">
            {/* Title & Description */}
            <div className="widget-2col-section">
              <div style={{ flex: '1 1 250px', minWidth: '250px' }}>
                <label style={styles.yourmsglabel}>Widget Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={handleTitleChange}
                  className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                  style={{ width: '100%' }}
                  placeholder="e.g. MyWebsiteChat"
                />
                {titleError && (
                  <span style={{ color: 'red', fontSize: '0.8em' }}>{titleError}</span>
                )}
              </div>

              <div style={{ flex: '1 1 250px', minWidth: '250px' }}>
                <label style={styles.yourmsglabel}>Description</label>
                <input
                  type="text"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                  style={{ width: '100%' }}
                  placeholder="Brief description of this widget"
                />
              </div>
            </div>

            {/* Flow Selection */}
            <div className="widget-2col-section">
              <div style={{ flex: '1 1 250px', minWidth: '250px' }}>
                <label style={styles.yourmsglabel}>Select Flow</label>
                <select
                  value={flow}
                  onChange={e => setFlow(e.target.value)}
                  className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                  style={{ width: '100%' }}
                >
                  <option value="">-- Choose a flow --</option>
                  {flows.map((flowName, index) => (
                    <option key={index} value={flowName}>{flowName}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* Header Icon & Title */}
            <div className="widget-2col-section">
              <div style={{ flex: '1 1 250px', minWidth: '250px' }}>
                <label style={styles.yourmsglabel}>Header Title</label>
                <input
                  type="text"
                  value={headerTitle}
                  onChange={e => setHeaderTitle(e.target.value)}
                  className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                  style={{ width: '100%' }}
                  placeholder="e.g. Support Chat"
                />
              </div>

              <div style={{ flex: '1 1 250px', minWidth: '250px' }}>
                <label style={styles.yourmsglabel}>Header Icon (URL)</label>
                <input
                  type="text"
                  value={headerIcon}
                  onChange={e => setHeaderIcon(e.target.value)}
                  placeholder="https://example.com/icon.png"
                  className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                  style={{ width: '100%' }}
                />
              </div>
            </div>

            {/* Messages */}
            <div className="widget-2col-section">
              <div style={{ flex: '1 1 250px', minWidth: '250px' }}>
                <label style={styles.yourmsglabel}>Initial Message</label>
                <textarea
                  value={initialMessage}
                  onChange={e => setInitialMessage(e.target.value)}
                  style={{ ...styles.textarea, fontSize: '.9rem', width: '100%', paddingLeft: '8px' }}
                  placeholder="What the assistant says first"
                />
              </div>

              <div style={{ flex: '1 1 250px', minWidth: '250px' }}>
                <label style={styles.yourmsglabel}>Fallback Reply</label>
                <textarea
                  value={stockReply}
                  onChange={e => setStockReply(e.target.value)}
                  style={{ ...styles.textarea, fontSize: '.9rem', width: '100%', paddingLeft: '8px' }}
                  placeholder="Message shown when AI fails"
                />
              </div>
            </div>

            {/* COLOR SETTINGS BUTTONS */}
            <div style={{ display: 'flex', gap: '1rem', margin: '1rem 0' }}>
              <button
                type="button"
                className="link-button"
                onClick={toggleColorPanel}
              >
                {showColorPanel ? 'Hide Colors' : 'Show Colors'}
              </button>
              
              <button
                type="button"
                className="link-button"
                onClick={resetToDefaults}
              >
                Reset
              </button>
            </div>

            {/* COLOR SETTINGS PANEL */}
            {showColorPanel && (
              <div className="color-panel">
                <div>
                  <label style={styles.yourmsglabel}>Primary Color</label>
                  <ChromePicker
                    color={primaryColor}
                    onChange={color => setPrimaryColor(color.hex)}
                    disableAlpha
                  />
                </div>

                <div>
                  <label style={styles.yourmsglabel}>Header BG</label>
                  <ChromePicker
                    color={headerColor}
                    onChange={color => setHeaderColor(color.hex)}
                    disableAlpha
                  />
                </div>

                <div>
                  <label style={styles.label}>Header Text</label>
                  <ChromePicker
                    color={headerTextColor}
                    onChange={color => setHeaderTextColor(color.hex)}
                    disableAlpha
                  />
                </div>

                <div>
                  <label style={styles.label}>User Bubble</label>
                  <ChromePicker
                    color={userBubbleColor}
                    onChange={color => setUserBubbleColor(color.hex)}
                    disableAlpha
                  />
                </div>

                <div>
                  <label style={styles.label}>User Text</label>
                  <ChromePicker
                    color={userTextColor}
                    onChange={color => setUserTextColor(color.hex)}
                    disableAlpha
                  />
                </div>

                <div>
                  <label style={styles.label}>Assistant Bubble</label>
                  <ChromePicker
                    color={assistantBubbleColor}
                    onChange={color => setAssistantBubbleColor(color.hex)}
                    disableAlpha
                  />
                </div>

                <div>
                  <label style={styles.label}>Assistant Text</label>
                  <ChromePicker
                    color={assistantTextColor}
                    onChange={color => setAssistantTextColor(color.hex)}
                    disableAlpha
                  />
                </div>

                <div>
                  <label style={styles.label}>BG Gradient Start</label>
                  <ChromePicker
                    color={backgroundGradientFrom}
                    onChange={color => setBackgroundGradientFrom(color.hex)}
                    disableAlpha
                  />
                </div>

                <div>
                  <label style={styles.label}>BG Gradient End</label>
                  <ChromePicker
                    color={backgroundGradientTo}
                    onChange={color => setBackgroundGradientTo(color.hex)}
                    disableAlpha
                  />
                </div>
              </div>
            )}

            {/* LIVE PREVIEW */}
            {/* <label style={{ ...styles.yourmsglabel, marginTop: '1rem' }}>Live Preview</label> */}
            <div className={previewClass} style={{marginTop: '10px'}}>
              <div
                className="widget-header"
                style={{
                  backgroundColor: headerColor,
                  color: headerTextColor,
                  paddingBottom: "20px"
                }}
              >
                <div className="widget-header-left">
                  {headerIcon ? (
                    <img
                      src={headerIcon}
                      alt="Header icon"
                      style={{ width: '20px', height: '20px' }}
                      onError={e => { e.target.style.display = 'none'; }}
                    />
                  ) : (
                    <div style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: 'rgba(255,255,255,0.2)',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      💬
                    </div>
                  )}
                  <span style={{ fontWeight: 'bold' }}>{headerTitle}</span>
                </div>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <span>🗑️</span>
                  <span>✕</span>
                </div>
              </div>

              <div
                className="widget-preview-body"
                style={{
                  background: `linear-gradient(to bottom right, ${backgroundGradientFrom}, ${backgroundGradientTo})`,
                  padding: '1rem',      
                  minHeight: '200px'
                }}
              >
                {/* Assistant Message */}
                <div
                  className="widget-message"
                  style={{
                    backgroundColor: assistantBubbleColor,
                    color: assistantTextColor
                  }}
                >
                  {initialMessage}
                </div>

                {/* User Message */}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div
                    className="widget-message widget-message-user"
                    style={{
                      backgroundColor: userBubbleColor,
                      color: userTextColor
                    }}
                  >
                    Sample user message
                  </div>
                </div>
              </div>
            </div>

            {/* DEPLOY / UPDATE BUTTON */}
            <button
              type="submit"
              style={{...styles.button, marginTop: '40px'}}
            >
              {isEditing ? 'Update Widget' : 'Deploy Widget'}
            </button>

            {/* LOADING SPINNER */}
            {isLoading && (
              <div className="loading-spinner" style={{ display: 'block' }}>
                <svg viewBox="0 0 50 50">
                  <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                </svg>
              </div>
            )}
          </form>
        </div>

        {/* EMBED SCRIPT SECTION */}
        {isWidgetDeployed && !isEditing && (
          <div style={{ marginTop: '2rem', paddingTop: '1.5rem', borderTop: '1px solid #ddd' }}>
            <h3 style={{ marginBottom: '0.5rem' }}>Widget Embed Script</h3>
            <p style={{ fontSize: '0.9rem', lineHeight: '1.4' }}>
              Copy and paste this script into your website's HTML (just before the <code>&lt;/header&gt;</code> tag):
            </p>

            <div style={{ position: 'relative', marginTop: '1rem' }}>
              <textarea
                readOnly
                className={isDarkMode ? 'sleek-textarea-dark' : 'sleek-textarea-light'}
                ref={r => r && (r.value = widgetScript)}
                style={{
                  ...styles.textarea,
                  minHeight: '150px',
                  fontFamily: 'monospace',
                  fontSize: '0.85em',
                  whiteSpace: 'pre',
                  overflowX: 'auto',
                  padding: '1rem'
                }}
              />
              <button
                onClick={copyScriptToClipboard}
                style={{
                  ...styles.button,
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  padding: '5px 10px',
                  fontSize: '0.85em'
                }}
              >
                {copyButtonText}
              </button>
            </div>

            <ul style={{ marginTop: '1rem', lineHeight: '1.6', fontSize: '0.9rem' }}>
              <li>Paste this script before the closing <code>&lt;/header&gt;</code> tag on your site.</li>
              <li>The widget will appear in the bottom-right corner automatically.</li>
            </ul>
          </div>
        )}

        {/* EXISTING DEPLOYMENTS */}
        <div style={{ marginTop: '3rem' }}>
          <h2 style={{ marginBottom: '1rem' }}>Your Widget Deployments</h2>
          <WidgetDeployments
            isDarkMode={isDarkMode}
            depsBrah={deployments}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onShowScript={handleShowScript}
          />
        </div>
      </div>
    </div>
  );
}