// makeDeployment.js
import { encrypt } from "../utils/crypt";
import { log } from "../utils/logger";

async function sendDeployRequest(
    user, 
    title, 
    vault, 
    forwardSlash, 
    chat, 
    data, 
    edit, 
    del, 
    model, 
    dark, 
    context, 
    description, 
    jsn,
    isPublic = true,
    users = []
) {
    const url = "https://deploy.vectorvault.io/deploy"
    const userEncrypted = encrypt(user)
    
    // Create base payload
    const payload = {
        env_vars: {
            USER: userEncrypted,
            TITLE: title,
            DESCRIPTION: description,
            FORWARDSLASH: forwardSlash,
            VAULT: vault,
            CHAT: chat,
            DATA: data,
            EDIT: edit,
            DELETE: del,
            MODEL: model,
            DARK: dark,
            CONTEXT: context,
            JSON: jsn,
            // Add new authentication params
            IS_PUBLIC: isPublic,
        }
    };
    
    // Add users array if not public
    if (!isPublic && Array.isArray(users) && users.length > 0) {
        // Filter out any incomplete user entries
        const validUsers = users.filter(user => user.username && user.password);
        
        if (validUsers.length > 0) {
            // Convert users array to format expected by server
            // Using a simple encoding for now - the server will need to handle this format
            payload.env_vars.USERS = JSON.stringify(validUsers);
        }
    }

    try {

        log('Deployment request payload:', payload);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get response text
            throw new Error('Server responded with an error: ' + errorText);
        }
    
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error("Error in POST request:", error);
        throw error; // Re-throw the error so it can be handled by the caller
    }
}

export default sendDeployRequest;

// // Example usage
// sendDeployRequest(
//     "john.rood@decision.com",
//     "Johns Awesome :)",
//     "hey",
//     "your_forward_slash",
//     true,
//     true,
//     false,
//     false,
//     "gpt-4o",
//     true,
//     false,
//     "This vector database contains The Prince",
//     false,
//     false, // Not public
//     [{ username: "admin", password: "secure123" }] // User credentials
// ).then(response => {
//     console.log("Response:", response);
// });