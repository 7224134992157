// DeployUserManagement.js
import React from 'react';

function UserManagement({ 
  isDarkMode, 
  isPublic, 
  setIsPublic, 
  users, 
  handleUserChange, 
  handleAddUser, 
  handleRemoveUser 
}) {
  return (
    <div>
      {/* User management section - shown only when not public */}
      {!isPublic && (
        <div style={{ 
          marginTop: '20px',
          marginBottom: '20px'
        }}>
          <h3 style={{ marginTop: 0, marginBottom: '15px' }}>Authorized Users</h3>
          <p style={{ marginBottom: '15px', fontSize: '0.9em' }}>
            Add users who will have access to this deployment. Each user will need to login with their credentials.
          </p>
          
          <div style={{ overflowX: 'auto' }}>
            <table style={{
              minWidth: '100%',
              width: '100%',
              borderCollapse: 'collapse',
              tableLayout: 'fixed',
              marginBottom: '15px'
            }}>
              <thead>
                <tr>
                  <th style={{
                    padding: '10px',
                    fontSize: '1.1rem',
                    border: `1px solid ${isDarkMode ? '#333' : '#e0e0e0'}`,
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: isDarkMode ? '#ddd' : '#333',
                    width: '45%',
                    backgroundColor: isDarkMode ? '#2e333c' : '#f7f7f7'
                  }}>Username</th>
                  <th style={{
                    padding: '10px',
                    fontSize: '1.1rem',
                    border: `1px solid ${isDarkMode ? '#333' : '#e0e0e0'}`,
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: isDarkMode ? '#ddd' : '#333',
                    width: '45%',
                    backgroundColor: isDarkMode ? '#2e333c' : '#f7f7f7'
                  }}>Password</th>
                  <th style={{
                    padding: '10px',
                    fontSize: '1.1rem',
                    border: `1px solid ${isDarkMode ? '#333' : '#e0e0e0'}`,
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: isDarkMode ? '#ddd' : '#333',
                    width: '10%',
                    backgroundColor: isDarkMode ? '#2e333c' : '#f7f7f7'
                  }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index} style={{
                    backgroundColor: index % 2 === 1 ? (isDarkMode ? '#2e333c' : '#f7f7f7') : 'transparent',
                    ':hover': {
                      backgroundColor: isDarkMode ? '#414d68' : '#eef6fc'
                    }
                  }}>
                    <td style={{
                      padding: '10px',
                      fontSize: '1.1rem',
                      border: `1px solid ${isDarkMode ? '#333' : '#e0e0e0'}`,
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      color: isDarkMode ? '#ddd' : '#333'
                    }}>
                      <input
                        type="text"
                        value={user.username}
                        onChange={e => handleUserChange(index, 'username', e.target.value)}
                        className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                        style={{ width: '100%' }}
                        placeholder="Enter username"
                      />
                    </td>
                    <td style={{
                      padding: '10px',
                      fontSize: '1.1rem',
                      border: `1px solid ${isDarkMode ? '#333' : '#e0e0e0'}`,
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      color: isDarkMode ? '#ddd' : '#333'
                    }}>
                      <input
                        type="password"
                        value={user.password}
                        onChange={e => handleUserChange(index, 'password', e.target.value)}
                        className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                        style={{ width: '100%' }}
                        placeholder="Enter password"
                      />
                    </td>
                    <td style={{
                      padding: '10px',
                      fontSize: '1.1rem',
                      border: `1px solid ${isDarkMode ? '#333' : '#e0e0e0'}`,
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      color: isDarkMode ? '#ddd' : '#333'
                    }}>
                      <button
                        type="button"
                        onClick={() => handleRemoveUser(index)}
                        style={{ 
                          border: 'none', 
                          background: 'none',
                          cursor: users.length > 1 ? 'pointer' : 'not-allowed',
                          opacity: users.length > 1 ? '1' : '0.5'
                        }}
                        disabled={users.length === 1}
                        title={users.length > 1 ? "Remove user" : "Cannot remove the only user"}
                      >
                        <svg 
                          style={{ 
                            width: '15px', 
                            height: '15px', 
                            fill: users.length > 1 ? (isDarkMode ? 'white' : '#333') : '#999' 
                          }} 
                          viewBox="0 0 448 512"
                        >
                          <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <button
            type="button"
            onClick={handleAddUser}
            className={isDarkMode ? 'btn-dark' : 'btn-light'}
            style={{ 
              marginBottom: '10px',
              marginLeft: '0px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <span style={{ marginRight: '5px', fontSize: '1.2em', lineHeight: '1em' }}>+</span> Add User
          </button>
        </div>
      )}
    </div>
  );
}

export default UserManagement;