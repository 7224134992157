import { encrypt } from "../utils/crypt";
import { log } from "../utils/logger"; // Assuming you have the logger here too

export const fetchApiKeys = async (setApiKeys, userEmail) => {
    try {
        const formData = new URLSearchParams();
        const userEncrypted = encrypt(userEmail);
        formData.append('email', userEncrypted);
        
        const response = await fetch('https://register.vectorvault.io/fetch_keys', {
            method: 'POST',
            headers: {
                'Authorization': 'unexpected_authorization_code'
            },
            body: formData
        });
        
        if (response.ok) {
            const data = await response.json();
            setApiKeys(data);
            return true;
        } else {
            // Handle specific HTTP status codes
            if (response.status === 401) {
                log('Authentication failed. Please log in again.');
                setApiKeys([]); // Set empty array as fallback
            } else if (response.status === 404) {
                log('No API keys found for this user.');
                setApiKeys([]); // Set empty array as fallback
            } else {
                log(`Failed to fetch API keys: ${response.status} ${response.statusText}`);
                setApiKeys([]); // Set empty array as fallback
            }
            return false;
        }
    } catch (error) {
        log('Error occurred during fetch:', error);
        setApiKeys([]); // Always set to empty array on error
        return false;
    }
};