// ChatControls.jsx
import React from 'react';
import '../dashboard/Dashboard.css';

/**
 * Renders chat-related controls in a horizontal row:
 *  - get_context checkbox
 *  - n_context text field
 *  - smart search checkbox
 *  - embeddings select
 *
 * Props:
 *   isDarkMode (bool)
 *   getContext (bool)
 *   setGetContext (function)
 *   nContext (number)
 *   setNContext (function)
 *   historySearch (bool)
 *   setHistorySearch (function)
 *   embeddings (string) - e.g. "text-embedding-3-small" or "text-embedding-3-large"
 *   setEmbeddings (function)
 *   styles (object) - if you wish to merge additional inline styles
 */
export default function ChatControls({
  isDarkMode,
  getContext,
  setGetContext,
  nContext,
  setNContext,
  historySearch,
  setHistorySearch,
  embeddings,
  setEmbeddings,
  styles,
}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px',}}>
      {/* get_context checkbox */}
      <input
        type="checkbox"
        id="vault-reply"
        className={isDarkMode ? 'sleek-checkbox-dark' : 'sleek-checkbox-light'}
        style={{ marginLeft: '10px', marginTop: '4px' }}
        onChange={(e) => setGetContext(e.target.checked)}
        checked={getContext}
        title="Check to use Vault context in the reply. Uncheck to go without Vault context."
      />
      <label
        htmlFor="vault-reply"
        style={{
          ...styles.text,
          marginLeft: '4px',
          marginTop: '4px',
          fontSize: '.85rem',
          marginRight: '25px',
        }}
        title="Check to use Vault context in the reply. Uncheck to go without Vault context."
      >
        get context
      </label>

      {/* n_context label + input */}
      <label
        htmlFor="n-context-input"
        style={{
          ...styles.text,
          marginLeft: '10px',
          marginTop: '4px',
          fontSize: '.85rem',
        }}
        title="n_context is how many context items to pull from the database on each search."
      >
        n_context:
      </label>

      <input
        id="n-context-input"
        type="text"
        className={isDarkMode ? 'sleek-input-dark' : 'sleek-input-light'}
        value={nContext}
        onChange={(e) => {
            const value = e.target.value;
            setNContext(value);

            // Determine the width based on the number of digits
            let width = '20px'; // Default width
            if (value.length === 2) {
            width = '30px';
            } else if (value.length > 2) {
            width = '40px';
            }

            // Update the width of the input
            e.target.style.width = width;
        }}
        style={{
            marginRight: '25px',
            marginLeft: '4px',
            marginBottom: '-2px',
            paddingLeft: '4px',
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingRight: '4px',
            fontSize: '.85rem',
            width: '20px', // Default width
        }}
        title="n_context is how many context items to pull from the database on each search."
        />

      {/* smart_search checkbox */}
      <input
        type="checkbox"
        id="smart-search"
        className={isDarkMode ? 'sleek-checkbox-dark' : 'sleek-checkbox-light'}
        style={{ marginLeft: '10px', marginTop: '4px' }}
        onChange={(e) => setHistorySearch(e.target.checked)}
        checked={historySearch}
        title="Smart search helps the AI find relevant context using your entire conversation history."
      />
      <label
        htmlFor="smart-search"
        style={{
          ...styles.text,
          marginLeft: '4px',
          marginTop: '4px',
          fontSize: '.85rem',
          marginRight: '25px',
        }}
        title="Smart search helps the AI find relevant context using your entire conversation history."
      >
        smart search
      </label>

      {/* embeddings select */}
      <label
        htmlFor="embeddings-select"
        style={{
          ...styles.text,
          marginLeft: '10px',
          marginTop: '4px',
          fontSize: '.85rem',
        }}
        title="Choose small or large embeddings for vector similarity. Large can be more accurate but uses more tokens."
      >
        embeddings:
      </label>
      <select
        id="embeddings-select"
        className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
        style={{
          marginLeft: '4px',
          marginTop: '2px',
          paddingLeft: '4px',
          paddingTop: '2px',
          paddingBottom: '2px',
          paddingRight: '4px',
          fontSize: '.85rem',
        }}
        value={embeddings}
        onChange={(e) => setEmbeddings(e.target.value)}
        title="Choose small or large embeddings for vector similarity. Large can be more accurate but uses more tokens."
      >
        {/* When user picks "small", store text-embedding-3-small. Large => text-embedding-3-large. */}
        <option value="text-embedding-3-small">small</option>
        <option value="text-embedding-3-large">large</option>
      </select>
    </div>
  );
}
