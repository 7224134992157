// utils/hashPassword.js
/**
 * hashString - returns a SHA-256 hash in hex format
 * @param {string} str The string (e.g. password) to hash
 * @returns {Promise<string>} The hex-encoded SHA-256 hash
 */
export async function hashPassword(str) {
    // Encode as UTF-8
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    
    // Hash the data
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    
    // Convert ArrayBuffer to Array of bytes
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    
    // Convert bytes to hex string
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    
    return hashHex;
  }