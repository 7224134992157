import React, { useState } from 'react';
import ChatControls from './ChatControls';

export default function AdvancedControls(props) {
  const { styles } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{ display: 'flex', marginTop: '6px' }}>
      {/* Expandable Button */}
      <div
        onClick={() => setIsOpen(prev => !prev)}
        style={{
          cursor: 'pointer',
          userSelect: 'none',
          display: 'flex',
          alignItems: 'center',
          padding: '6px 10px',
          marginTop: '10px'
        }}
      >
        <span style={{
          display: 'inline-block',
          transition: 'transform 0.2s',
          transform: isOpen ? 'rotate(180deg)' : 'rotate(00deg)',
          marginLeft: '4px',
          marginRight: '6px',
          marginTop: isOpen ? '2px' : '4px'
        }}>
          ▶
        </span>
        {/* <p style={{...styles.response}}>
            Advanced
        </p> */}
      </div>

      <div style={{
        width: isOpen ? 'auto' : 0,
        maxWidth: isOpen ? '1200px' : '0px',
        transition: 'max-width 0.3s ease-out, opacity 0.2s',
        opacity: isOpen ? 1 : 0,
        paddingTop: '7px'
      }}>
        {isOpen && <ChatControls {...props} />}
      </div>
    </div>
  );
}
